<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.92 7.62409C11.8724 7.50134 11.801 7.38919 11.71 7.29409L9.21 4.79409C9.0217 4.60579 8.7663 4.5 8.5 4.5C8.2337 4.5 7.9783 4.60579 7.79 4.79409C7.6017 4.98239 7.49591 5.23779 7.49591 5.50409C7.49591 5.77039 7.6017 6.02579 7.79 6.21409L8.59 7.00409H4C3.73478 7.00409 3.48043 7.10945 3.29289 7.29698C3.10536 7.48452 3 7.73887 3 8.00409C3 8.26931 3.10536 8.52366 3.29289 8.7112C3.48043 8.89873 3.73478 9.00409 4 9.00409H8.59L7.79 9.79409C7.69627 9.88705 7.62188 9.99766 7.57111 10.1195C7.52034 10.2414 7.4942 10.3721 7.4942 10.5041C7.4942 10.6361 7.52034 10.7668 7.57111 10.8887C7.62188 11.0105 7.69627 11.1211 7.79 11.2141C7.88296 11.3078 7.99357 11.3822 8.11542 11.433C8.23728 11.4838 8.36799 11.5099 8.5 11.5099C8.63201 11.5099 8.76272 11.4838 8.88458 11.433C9.00644 11.3822 9.11704 11.3078 9.21 11.2141L11.71 8.71409C11.801 8.61899 11.8724 8.50684 11.92 8.38409C12.02 8.14063 12.02 7.86755 11.92 7.62409Z"
      fill="currentColor"
    />
  </svg>
</span>
