<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 7.56797C11.2537 7.5459 11.2537 7.52337 11.25 7.5013V6.66797H14.5833C14.8044 6.66797 15.0163 6.58017 15.1726 6.42389C15.3289 6.26761 15.4167 6.05565 15.4167 5.83464V2.5013C15.4167 2.28029 15.3289 2.06833 15.1726 1.91205C15.0163 1.75577 14.8044 1.66797 14.5833 1.66797H6.25001C6.029 1.66797 5.81703 1.75577 5.66075 1.91205C5.50447 2.06833 5.41668 2.28029 5.41668 2.5013V5.83464C5.41668 6.05565 5.50447 6.26761 5.66075 6.42389C5.81703 6.58017 6.029 6.66797 6.25001 6.66797H9.58334V7.5013C9.57961 7.52337 9.57961 7.5459 9.58334 7.56797C8.23534 7.77785 7.01617 8.48867 6.16947 9.55837C5.32277 10.6281 4.9109 11.9779 5.01616 13.3381C5.12141 14.6982 5.73604 15.9686 6.73723 16.8953C7.73842 17.822 9.05243 18.3368 10.4167 18.3368C11.7809 18.3368 13.0949 17.822 14.0961 16.8953C15.0973 15.9686 15.7119 14.6982 15.8172 13.3381C15.9225 11.9779 15.5106 10.6281 14.6639 9.55837C13.8172 8.48867 12.598 7.77785 11.25 7.56797ZM7.08334 3.33464H13.75V5.0013H7.08334V3.33464ZM10.4167 16.668C9.675 16.668 8.94997 16.448 8.33329 16.036C7.7166 15.6239 7.23596 15.0383 6.95213 14.353C6.6683 13.6678 6.59404 12.9138 6.73873 12.1864C6.88343 11.459 7.24058 10.7908 7.76503 10.2663C8.28947 9.74187 8.95766 9.38472 9.68509 9.24002C10.4125 9.09533 11.1665 9.16959 11.8517 9.45342C12.537 9.73725 13.1226 10.2179 13.5347 10.8346C13.9467 11.4513 14.1667 12.1763 14.1667 12.918C14.1645 13.9119 13.7687 14.8644 13.0659 15.5672C12.3631 16.27 11.4106 16.6658 10.4167 16.668Z"
      fill="currentColor"
    />
    <path
      d="M12.4663 12.1245L11.4413 11.9745C11.386 11.9693 11.333 11.9496 11.2876 11.9174C11.2423 11.8853 11.2062 11.8417 11.183 11.7911L10.7247 10.8328C10.6947 10.7771 10.6503 10.7305 10.596 10.698C10.5417 10.6656 10.4796 10.6484 10.4163 10.6484C10.3531 10.6484 10.291 10.6656 10.2367 10.698C10.1824 10.7305 10.1379 10.7771 10.108 10.8328L9.64967 11.7661C9.6265 11.8167 9.59043 11.8603 9.54508 11.8924C9.49973 11.9246 9.44671 11.9443 9.39134 11.9495L8.36634 12.0995C8.30294 12.1082 8.24328 12.1346 8.19413 12.1756C8.14499 12.2165 8.10834 12.2705 8.08836 12.3313C8.06839 12.3921 8.06589 12.4573 8.08115 12.5194C8.0964 12.5816 8.12881 12.6382 8.17467 12.6828L8.91634 13.4078C8.95663 13.4473 8.98677 13.496 9.00418 13.5497C9.0216 13.6034 9.02577 13.6605 9.01634 13.7161L8.84134 14.7411C8.8315 14.8033 8.83901 14.867 8.86304 14.9251C8.88708 14.9833 8.92671 15.0336 8.97756 15.0707C9.02842 15.1078 9.08851 15.1301 9.15123 15.1351C9.21395 15.1402 9.27686 15.1279 9.33301 15.0995L10.258 14.6162C10.3071 14.5913 10.3613 14.5783 10.4163 14.5783C10.4714 14.5783 10.5256 14.5913 10.5747 14.6162L11.4997 15.0995C11.5558 15.1279 11.6187 15.1402 11.6814 15.1351C11.7442 15.1301 11.8043 15.1078 11.8551 15.0707C11.906 15.0336 11.9456 14.9833 11.9696 14.9251C11.9937 14.867 12.0012 14.8033 11.9913 14.7411L11.8163 13.7161C11.8069 13.6605 11.8111 13.6034 11.8285 13.5497C11.8459 13.496 11.876 13.4473 11.9163 13.4078L12.658 12.6828C12.697 12.6376 12.7235 12.583 12.7349 12.5243C12.7462 12.4657 12.7419 12.4051 12.7226 12.3486C12.7032 12.2921 12.6693 12.2417 12.6243 12.2024C12.5794 12.163 12.5249 12.1362 12.4663 12.1245Z"
      fill="currentColor"
    />
  </svg>
</span>
