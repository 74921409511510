<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6667 6.67117C16.5758 6.67487 16.486 6.69172 16.4 6.72117C15.9048 5.43962 15.022 4.34456 13.8748 3.58868C12.7275 2.8328 11.373 2.45379 10 2.5045C8.62705 2.45379 7.27249 2.8328 6.12523 3.58868C4.97797 4.34456 4.09519 5.43962 3.6 6.72117C3.51399 6.69172 3.42417 6.67487 3.33333 6.67117C3.11232 6.67117 2.90036 6.75896 2.74408 6.91524C2.5878 7.07152 2.5 7.28349 2.5 7.5045V9.17117C2.5 9.39218 2.5878 9.60414 2.74408 9.76042C2.90036 9.9167 3.11232 10.0045 3.33333 10.0045C3.42417 10.0008 3.51399 9.98395 3.6 9.9545C4.04059 11.2251 4.90206 12.3071 6.04167 13.0212C5.28678 14.0537 4.7534 15.2311 4.475 16.4795C4.44644 16.5873 4.43982 16.6998 4.45554 16.8103C4.47125 16.9207 4.50898 17.0269 4.56649 17.1225C4.624 17.218 4.70012 17.3011 4.79033 17.3667C4.88054 17.4324 4.98301 17.4792 5.09167 17.5045H5.28333C5.47483 17.5096 5.66224 17.4486 5.81402 17.3317C5.9658 17.2149 6.07268 17.0493 6.11667 16.8628C6.36935 15.7264 6.88928 14.6666 7.63333 13.7712C8.39463 14.0346 9.19441 14.1698 10 14.1712C10.8167 14.1719 11.6277 14.0368 12.4 13.7712C13.1384 14.6691 13.6551 15.7282 13.9083 16.8628C13.9523 17.0493 14.0592 17.2149 14.211 17.3317C14.3628 17.4486 14.5502 17.5096 14.7417 17.5045H14.9333C15.1474 17.4531 15.3323 17.319 15.4479 17.1317C15.5634 16.9443 15.6001 16.7188 15.55 16.5045C15.2716 15.2561 14.7382 14.0787 13.9833 13.0462C15.1229 12.3321 15.9844 11.2501 16.425 9.9795C16.5041 9.99823 16.5854 10.0066 16.6667 10.0045C16.8877 10.0045 17.0996 9.9167 17.2559 9.76042C17.4122 9.60414 17.5 9.39218 17.5 9.17117V7.5045C17.5 7.28349 17.4122 7.07152 17.2559 6.91524C17.0996 6.75896 16.8877 6.67117 16.6667 6.67117ZM10 12.5045C7.24167 12.5045 5 10.6378 5 8.33783C5 6.03783 7.24167 4.17117 10 4.17117C12.7583 4.17117 15 6.03783 15 8.33783C15 10.6378 12.7583 12.5045 10 12.5045Z"
      fill="currentColor"
    />
  </svg>
</span>
