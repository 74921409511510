<span nz-icon>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.29008 14.7C8.38008 14.79 8.49008 14.87 8.62008 14.92C8.74008 14.97 8.87008 15 9.00008 15C9.13008 15 9.26008 14.97 9.38008 14.92C9.50008 14.87 9.61008 14.8 9.71008 14.7L11.7101 12.7C12.1001 12.31 12.1001 11.68 11.7101 11.29C11.3201 10.9 10.6901 10.9 10.3001 11.29L10.0101 11.58V8.98999C10.0101 8.43999 9.56008 7.98999 9.01008 7.98999C8.46008 7.98999 8.01008 8.43999 8.01008 8.98999V11.58L7.72008 11.29C7.33008 10.9 6.70008 10.9 6.31008 11.29C5.92008 11.68 5.92008 12.31 6.31008 12.7L8.31008 14.7H8.29008Z"
      fill="currentColor"
    />
    <path
      d="M12.9996 6C13.2596 6 13.5096 5.9 13.7096 5.71L13.9996 5.42V14C13.9996 14.55 14.4496 15 14.9996 15C15.5496 15 15.9996 14.55 15.9996 14V5.41L16.2896 5.7C16.4896 5.9 16.7396 5.99 16.9996 5.99C17.2596 5.99 17.5096 5.89 17.7096 5.7C18.0996 5.31 18.0996 4.68 17.7096 4.29L15.7096 2.29C15.6196 2.2 15.5096 2.12 15.3796 2.07C15.1396 1.97 14.8596 1.97 14.6196 2.07C14.4996 2.12 14.3896 2.19 14.2896 2.29L12.2896 4.29C11.8996 4.68 11.8996 5.31 12.2896 5.7C12.4896 5.9 12.7396 5.99 12.9996 5.99V6Z"
      fill="currentColor"
    />
    <path
      d="M20.0002 15C19.4502 15 19.0002 15.45 19.0002 16V16.81C18.1102 16.4 17.0202 16.56 16.2902 17.29C16.1302 17.45 15.8702 17.45 15.7002 17.29C14.7602 16.35 13.2302 16.35 12.2902 17.29C12.1302 17.45 11.8702 17.45 11.7002 17.29C10.7602 16.35 9.23023 16.35 8.29023 17.29C8.13023 17.45 7.87023 17.45 7.70023 17.29C6.97023 16.56 5.88023 16.4 4.99023 16.81V16C4.99023 15.45 4.54023 15 3.99023 15C3.44023 15 2.99023 15.45 2.99023 16V21C2.99023 21.55 3.44023 22 3.99023 22C4.54023 22 4.99023 21.55 4.99023 21V19.41L5.70023 18.7C5.86023 18.54 6.12023 18.54 6.29023 18.7C7.23023 19.64 8.76023 19.64 9.70023 18.7C9.91023 18.49 10.0802 18.49 10.2902 18.7C11.2302 19.64 12.7602 19.64 13.7002 18.7C13.9102 18.49 14.0802 18.49 14.2902 18.7C15.2302 19.64 16.7602 19.64 17.7002 18.7C17.8602 18.54 18.1202 18.54 18.2902 18.7L19.0002 19.41V21C19.0002 21.55 19.4502 22 20.0002 22C20.5502 22 21.0002 21.55 21.0002 21V16C21.0002 15.45 20.5502 15 20.0002 15Z"
      fill="currentColor"
    />
  </svg>
</span>
