<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="16" y="4" width="2" height="16" rx="1" fill="#28B16F" />
    <rect x="11" y="8" width="2" height="12" rx="1" fill="#28B16F" />
    <rect x="6" y="12" width="2" height="8" rx="1" fill="#28B16F" />
  </svg>
</span>
