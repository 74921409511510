<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#F39200" />
    <path
      d="M16 6.5H8C7.60218 6.5 7.22064 6.65804 6.93934 6.93934C6.65804 7.22064 6.5 7.60218 6.5 8V16C6.5 16.3978 6.65804 16.7794 6.93934 17.0607C7.22064 17.342 7.60218 17.5 8 17.5H16C16.3978 17.5 16.7794 17.342 17.0607 17.0607C17.342 16.7794 17.5 16.3978 17.5 16V8C17.5 7.60218 17.342 7.22064 17.0607 6.93934C16.7794 6.65804 16.3978 6.5 16 6.5ZM16.5 16C16.5 16.1326 16.4473 16.2598 16.3536 16.3536C16.2598 16.4473 16.1326 16.5 16 16.5H8C7.86739 16.5 7.74021 16.4473 7.64645 16.3536C7.55268 16.2598 7.5 16.1326 7.5 16V11.5H16.5V16ZM16.5 10.5H7.5V8C7.5 7.86739 7.55268 7.74021 7.64645 7.64645C7.74021 7.55268 7.86739 7.5 8 7.5H16C16.1326 7.5 16.2598 7.55268 16.3536 7.64645C16.4473 7.74021 16.5 7.86739 16.5 8V10.5Z"
      fill="white"
    />
  </svg>
</span>
