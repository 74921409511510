<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00133 1.50106V1.50111L8.00869 1.50098C9.65761 1.47231 11.2558 2.07149 12.4794 3.17714C13.7031 4.28278 14.4607 5.81221 14.5988 7.45558C14.737 9.09896 14.2453 10.7334 13.2233 12.0278C12.2091 13.3125 10.7477 14.1669 9.13165 14.4211H9V14.421L8.99357 14.4211C8.87372 14.4226 8.75731 14.3811 8.66553 14.304C8.57376 14.2269 8.51273 14.1194 8.49357 14.0011L8.49337 13.9999C8.48267 13.9349 8.48493 13.8684 8.50002 13.8042C8.51511 13.7401 8.54273 13.6795 8.5813 13.6261C8.61987 13.5726 8.66862 13.5274 8.72475 13.4928C8.78087 13.4583 8.84327 13.4352 8.90837 13.4249L8.90837 13.4249L8.91295 13.4241C9.79925 13.275 10.6355 12.911 11.3486 12.3639C12.0616 11.8168 12.6297 11.1033 13.0032 10.2858C13.3767 9.4683 13.5441 8.57174 13.4909 7.67456C13.4376 6.77737 13.1654 5.90691 12.6978 5.13932C12.2303 4.37173 11.5818 3.7304 10.809 3.27146C10.0363 2.81252 9.16285 2.54994 8.26513 2.50671C7.36741 2.46347 6.47277 2.64089 5.65949 3.02344C4.84621 3.406 4.13907 3.98203 3.59995 4.70113L3.00022 5.50106H4H6C6.13261 5.50106 6.25979 5.55374 6.35355 5.6475C6.44732 5.74127 6.5 5.86845 6.5 6.00106C6.5 6.13367 6.44732 6.26085 6.35355 6.35461C6.25979 6.44838 6.13261 6.50106 6 6.50106H2C1.86739 6.50106 1.74021 6.44838 1.64645 6.35461C1.55268 6.26085 1.5 6.13367 1.5 6.00106V2.00106C1.5 1.86845 1.55268 1.74127 1.64645 1.6475C1.74021 1.55374 1.86739 1.50106 2 1.50106C2.13261 1.50106 2.25979 1.55374 2.35355 1.6475C2.44732 1.74127 2.5 1.86845 2.5 2.00106V3.12106V4.35247L3.35847 3.46963C3.9622 2.84876 4.6839 2.35479 5.4812 2.01673C6.27851 1.67868 7.13532 1.50335 8.00133 1.50106Z"
      fill="black"
      stroke="currentColor"
    />
  </svg>
</span>
