<span nz-icon>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 10C20.21 10 22 8.21 22 6C22 3.79 20.21 2 18 2C15.79 2 14 3.79 14 6C14 8.21 15.79 10 18 10ZM18 4C19.1 4 20 4.9 20 6C20 7.1 19.1 8 18 8C16.9 8 16 7.1 16 6C16 4.9 16.9 4 18 4Z"
      fill="currentColor"
    />
    <path
      d="M6 14C3.79 14 2 15.79 2 18C2 20.21 3.79 22 6 22C8.21 22 10 20.21 10 18C10 15.79 8.21 14 6 14ZM6 20C4.9 20 4 19.1 4 18C4 16.9 4.9 16 6 16C7.1 16 8 16.9 8 18C8 19.1 7.1 20 6 20Z"
      fill="currentColor"
    />
    <path
      d="M11.0003 4H7.00031C5.35031 4 4.00031 5.35 4.00031 7V8.59L3.71031 8.3C3.32031 7.91 2.69031 7.91 2.30031 8.3C1.91031 8.69 1.91031 9.32 2.30031 9.71L4.30031 11.71C4.39031 11.8 4.50031 11.88 4.63031 11.93C4.75031 11.98 4.88031 12.01 5.01031 12.01C5.14031 12.01 5.27031 11.98 5.39031 11.93C5.51031 11.88 5.62031 11.81 5.72031 11.71L7.72031 9.71C8.11031 9.32 8.11031 8.69 7.72031 8.3C7.33031 7.91 6.70031 7.91 6.31031 8.3L6.02031 8.59V7C6.02031 6.45 6.47031 6 7.02031 6H11.0203C11.5703 6 12.0203 5.55 12.0203 5C12.0203 4.45 11.5703 4 11.0203 4H11.0003Z"
      fill="currentColor"
    />
    <path
      d="M19.71 12.29C19.62 12.2 19.51 12.12 19.38 12.07C19.14 11.97 18.86 11.97 18.62 12.07C18.5 12.12 18.39 12.19 18.29 12.29L16.29 14.29C15.9 14.68 15.9 15.31 16.29 15.7C16.49 15.9 16.74 15.99 17 15.99C17.26 15.99 17.51 15.89 17.71 15.7L18 15.41V17C18 17.55 17.55 18 17 18H13C12.45 18 12 18.45 12 19C12 19.55 12.45 20 13 20H17C18.65 20 20 18.65 20 17V15.41L20.29 15.7C20.49 15.9 20.74 15.99 21 15.99C21.26 15.99 21.51 15.89 21.71 15.7C22.1 15.31 22.1 14.68 21.71 14.29L19.71 12.29V12.29Z"
      fill="currentColor"
    />
  </svg>
</span>
