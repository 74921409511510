<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8947 2.6974C11.5527 -0.83276 6.05954 -0.90622 2.6232 2.526C-0.809033 5.9623 -0.882933 11.6065 2.45898 15.1326L4.65544 12.9369C2.50003 10.6474 2.5534 6.9989 4.7745 4.77469C6.9956 2.55048 10.551 2.59538 12.7187 4.87264L14.8906 2.70148L14.8947 2.6974Z"
      fill="currentColor"
    />
    <path
      d="M0.295898 17.3023C3.63781 20.8284 9.13102 20.906 12.5633 17.4738C15.9955 14.0415 16.0694 8.39735 12.7275 4.86719L10.531 7.06283C12.6864 9.35233 12.633 13.0049 10.4119 15.2251C8.19085 17.4493 4.63545 17.4044 2.46773 15.1271L0.295898 17.3023Z"
      fill="currentColor"
    />
  </svg>
</span>
