<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.83471 2.68428L0.592323 13.4314C-0.630698 15.5343 0.0942601 18.2238 2.2138 19.4358C3.0439 19.9117 3.92935 20.0058 4.85906 20.0058H17.4822C19.9282 20.0058 21.9094 18.0357 21.9094 15.6118C21.9094 14.7761 21.5884 13.9737 20.9576 12.8835L14.7484 2.19728C13.5254 0.0943483 10.8192 -0.625076 8.69968 0.592411C7.83084 1.09047 7.40472 1.71582 6.83471 2.68981V2.68428ZM8.5392 9.10376C9.3361 10.4817 9.3361 12.1751 8.5392 13.5531L6.60782 16.8791C5.91053 18.0744 4.37207 18.4839 3.16565 17.7977C1.95923 17.106 1.54971 15.573 2.24147 14.3777L6.92879 6.30907C6.97307 6.40315 7.02841 6.49723 7.07821 6.5913L8.5392 9.10376ZM14.527 11.179C12.9498 11.179 11.4833 10.3434 10.6919 8.97647L8.57794 5.34061C8.10754 4.2006 8.55026 2.86137 9.65154 2.23049C10.858 1.53873 12.3964 1.94825 13.0937 3.14914L17.7589 11.179H14.527ZM8.14628 18.1187H17.4822C18.8712 18.1187 20.0002 16.9953 20.0002 15.6173C20.0002 14.2393 18.8712 13.1159 17.4822 13.1159H13.6028C12.0201 13.1159 10.5647 13.9571 9.77329 15.324L8.15181 18.1187H8.14628Z"
      fill="currentColor"
    />
  </svg>
</span>
