<div
  class="relative w-6 h-6 overflow-hidden cursor-pointer rounded-2xl"
  *ngIf="deviceService.isDesktop$ | async; else isMobileBlock"
>
  <div
    class="absolute flex flex-col justify-between w-full h-16 overflow-visible transition-transform duration-1000 ease-in-out t-0 l-0 wrapper text-icons-primary dark:text-icons-primary-dark"
    [ngClass]="{
      'rotate-180': (currentTheme$ | async) === theme.Light
    }"
  >
    <wen-icon-moon (click)="onClickChangeTheme(theme.Light)"> </wen-icon-moon>
    <wen-icon-sun (click)="onClickChangeTheme(theme.Dark)"> </wen-icon-sun>
  </div>
</div>

<ng-template #isMobileBlock>
  <div class="flex items-center h-16 space-x-7">
    <div
      (click)="onClickChangeTheme(theme.Light)"
      class="flex items-center justify-center w-10 h-10 rounded-full"
      [ngClass]="{
        'bg-accent-primary dark:bg-accent-primary-dark text-foregrounds-on-primary dark:text-foregrounds-on-primary-dark':
          (currentTheme$ | async) === theme.Light
      }"
    >
      <wen-icon-sun> </wen-icon-sun>
    </div>

    <div
      (click)="onClickChangeTheme(theme.Dark)"
      class="flex items-center justify-center w-10 h-10 rounded-full"
      [ngClass]="{
        'bg-accent-primary dark:bg-accent-primary-dark text-foregrounds-on-primary dark:text-foregrounds-on-primary-dark':
          (currentTheme$ | async) === theme.Dark
      }"
    >
      <wen-icon-moon> </wen-icon-moon>
    </div>

    <ng-content></ng-content>
  </div>
</ng-template>
