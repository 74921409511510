<label
  class="w-full lg:w-auto"
  nz-checkbox
  [nzValue]="isChecked"
  [nzDisabled]="disabled"
  (nzCheckedChange)="onChange($event)"
>
  <div class="text-base font-medium">
    <span class="text-foregrounds-primary dark:text-foregrounds-primary-dark mr-0.5">{{
      label
    }}</span>
    <br *ngIf="deviceService.isMobile$ | async" />
    <a
      *ngIf="showLink"
      [href]="documentLink"
      target="_blank"
      class="inline underline wen-btn-link"
      (click)="$event.stopPropagation()"
      >{{ linkLabel }}</a
    >
  </div>
</label>
