<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21446 17.7727 6.23985 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
      fill="currentColor"
    />
    <path
      d="M10.7901 6.11986C9.63881 6.35678 8.58251 6.92661 7.75229 7.75861C6.92208 8.5906 6.35451 9.64812 6.12005 10.7999C6.0908 10.93 6.08787 11.0646 6.11144 11.1959C6.135 11.3271 6.18458 11.4524 6.25726 11.5642C6.32994 11.676 6.42425 11.7721 6.53464 11.847C6.64503 11.9218 6.76927 11.9738 6.90005 11.9999H7.10005C7.334 12.0046 7.56219 11.9271 7.74491 11.7809C7.92763 11.6348 8.0533 11.4291 8.10005 11.1999C8.25612 10.4325 8.634 9.72778 9.18685 9.17316C9.73969 8.61854 10.4432 8.23839 11.2101 8.07986C11.3414 8.0536 11.4662 8.00172 11.5775 7.9272C11.6888 7.85268 11.7843 7.75698 11.8586 7.64555C11.9329 7.53412 11.9845 7.40915 12.0105 7.27777C12.0365 7.14639 12.0363 7.01119 12.0101 6.87986C11.9838 6.74854 11.9319 6.62368 11.8574 6.5124C11.7829 6.40113 11.6872 6.30562 11.5757 6.23133C11.4643 6.15705 11.3393 6.10543 11.208 6.07944C11.0766 6.05346 10.9414 6.0536 10.8101 6.07986L10.7901 6.11986Z"
      fill="currentColor"
    />
  </svg>
</span>
