<span nz-icon>
  <svg
    *ngIf="!selected"
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0005 9.67015C21.9373 9.48723 21.8224 9.32657 21.6698 9.20765C21.5171 9.08872 21.3333 9.01664 21.1405 9.00014L15.4505 8.17015L12.9005 3.00015C12.8186 2.83107 12.6907 2.68849 12.5316 2.58872C12.3724 2.48895 12.1883 2.43604 12.0005 2.43604C11.8126 2.43604 11.6286 2.48895 11.4694 2.58872C11.3102 2.68849 11.1824 2.83107 11.1005 3.00015L8.55047 8.16014L2.86047 9.00014C2.67539 9.02645 2.50139 9.10411 2.35822 9.2243C2.21504 9.3445 2.10843 9.50242 2.05047 9.68015C1.99741 9.85382 1.99265 10.0387 2.03669 10.2148C2.08074 10.391 2.17192 10.5519 2.30047 10.6801L6.43047 14.6801L5.43047 20.3601C5.39477 20.5476 5.41346 20.7414 5.48434 20.9186C5.55522 21.0958 5.67532 21.249 5.83047 21.3601C5.98168 21.4683 6.16004 21.5321 6.34551 21.5444C6.53099 21.5568 6.71624 21.5172 6.88047 21.4301L12.0005 18.7601L17.1005 21.4401C17.2408 21.5193 17.3993 21.5607 17.5605 21.5601C17.7723 21.5609 17.9789 21.4944 18.1505 21.3701C18.3056 21.259 18.4257 21.1058 18.4966 20.9286C18.5675 20.7514 18.5862 20.5576 18.5505 20.3701L17.5505 14.6901L21.6805 10.6901C21.8248 10.5678 21.9316 10.407 21.9882 10.2265C22.0448 10.0459 22.0491 9.85302 22.0005 9.67015ZM15.8505 13.6701C15.7332 13.7836 15.6454 13.924 15.5949 14.0791C15.5444 14.2343 15.5325 14.3994 15.5605 14.5601L16.2805 18.7501L12.5205 16.7501C12.3758 16.6731 12.2144 16.6328 12.0505 16.6328C11.8865 16.6328 11.7251 16.6731 11.5805 16.7501L7.82047 18.7501L8.54047 14.5601C8.5684 14.3994 8.55658 14.2343 8.50603 14.0791C8.45548 13.924 8.36774 13.7836 8.25047 13.6701L5.25047 10.6701L9.46047 10.0601C9.62246 10.0376 9.77646 9.97569 9.90896 9.8798C10.0415 9.78391 10.1484 9.65698 10.2205 9.51015L12.0005 5.70015L13.8805 9.52015C13.9525 9.66698 14.0595 9.79391 14.192 9.8898C14.3245 9.98569 14.4785 10.0476 14.6405 10.0701L18.8505 10.6801L15.8505 13.6701Z"
      fill="currentColor"
    />
  </svg>
  <svg
    *ngIf="selected"
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0005 9.67015C21.9373 9.48723 21.8224 9.32657 21.6698 9.20765C21.5171 9.08872 21.3333 9.01664 21.1405 9.00014L15.4505 8.17015L12.9005 3.00015C12.8186 2.83107 12.6907 2.68849 12.5316 2.58872C12.3724 2.48895 12.1883 2.43604 12.0005 2.43604C11.8126 2.43604 11.6286 2.48895 11.4694 2.58872C11.3102 2.68849 11.1824 2.83107 11.1005 3.00015L8.55047 8.16014L2.86047 9.00014C2.67539 9.02645 2.50139 9.10411 2.35822 9.2243C2.21504 9.3445 2.10843 9.50242 2.05047 9.68015C1.99741 9.85382 1.99265 10.0387 2.03669 10.2148C2.08074 10.391 2.17192 10.5519 2.30047 10.6801L6.43047 14.6801L5.43047 20.3601C5.39477 20.5476 5.41346 20.7414 5.48434 20.9186C5.55522 21.0958 5.67532 21.249 5.83047 21.3601C5.98168 21.4683 6.16004 21.5321 6.34551 21.5444C6.53099 21.5568 6.71624 21.5172 6.88047 21.4301L12.0005 18.7601L17.1005 21.4401C17.2408 21.5193 17.3993 21.5607 17.5605 21.5601C17.7723 21.5609 17.9789 21.4944 18.1505 21.3701C18.3056 21.259 18.4257 21.1058 18.4966 20.9286C18.5675 20.7514 18.5862 20.5576 18.5505 20.3701L17.5505 14.6901L21.6805 10.6901C21.8248 10.5678 21.9316 10.407 21.9882 10.2265C22.0448 10.0459 22.0491 9.85302 22.0005 9.67015Z"
      fill="#F39200"
    />
  </svg>
</span>
