<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2899 16.29L8.99994 17.59V7C8.99994 6.73478 8.89458 6.48043 8.70705 6.29289C8.51951 6.10536 8.26516 6 7.99994 6C7.73472 6 7.48037 6.10536 7.29283 6.29289C7.1053 6.48043 6.99994 6.73478 6.99994 7V17.59L5.70994 16.29C5.52164 16.1017 5.26624 15.9959 4.99994 15.9959C4.73364 15.9959 4.47824 16.1017 4.28994 16.29C4.10164 16.4783 3.99585 16.7337 3.99585 17C3.99585 17.2663 4.10164 17.5217 4.28994 17.71L7.28994 20.71C7.38504 20.801 7.49719 20.8724 7.61994 20.92C7.73964 20.9729 7.86907 21.0002 7.99994 21.0002C8.13081 21.0002 8.26024 20.9729 8.37994 20.92C8.50269 20.8724 8.61484 20.801 8.70994 20.71L11.7099 17.71C11.8982 17.5217 12.004 17.2663 12.004 17C12.004 16.7337 11.8982 16.4783 11.7099 16.29C11.5216 16.1017 11.2662 15.9959 10.9999 15.9959C10.7336 15.9959 10.4782 16.1017 10.2899 16.29Z"
      fill="currentColor"
    />
    <path
      d="M19.7099 6.28999L16.7099 3.28999C16.6148 3.19895 16.5027 3.12758 16.3799 3.07999C16.1365 2.97997 15.8634 2.97997 15.6199 3.07999C15.4972 3.12758 15.385 3.19895 15.2899 3.28999L12.2899 6.28999C12.1962 6.38295 12.1218 6.49355 12.071 6.61541C12.0203 6.73727 11.9941 6.86798 11.9941 6.99999C11.9941 7.132 12.0203 7.2627 12.071 7.38456C12.1218 7.50642 12.1962 7.61702 12.2899 7.70999C12.3829 7.80372 12.4935 7.87811 12.6154 7.92888C12.7372 7.97965 12.8679 8.00579 12.9999 8.00579C13.132 8.00579 13.2627 7.97965 13.3845 7.92888C13.5064 7.87811 13.617 7.80372 13.7099 7.70999L14.9999 6.40999V17C14.9999 17.2652 15.1053 17.5196 15.2928 17.7071C15.4804 17.8946 15.7347 18 15.9999 18C16.2652 18 16.5195 17.8946 16.707 17.7071C16.8946 17.5196 16.9999 17.2652 16.9999 17V6.40999L18.2899 7.70999C18.3829 7.80372 18.4935 7.87811 18.6154 7.92888C18.7372 7.97965 18.8679 8.00579 18.9999 8.00579C19.132 8.00579 19.2627 7.97965 19.3845 7.92888C19.5064 7.87811 19.617 7.80372 19.7099 7.70999C19.8037 7.61702 19.8781 7.50642 19.9288 7.38456C19.9796 7.2627 20.0057 7.132 20.0057 6.99999C20.0057 6.86798 19.9796 6.73727 19.9288 6.61541C19.8781 6.49355 19.8037 6.38295 19.7099 6.28999Z"
      fill="currentColor"
    />
  </svg>
</span>
