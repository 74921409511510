<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 4C5.45 4 5 3.55 5 3V1C5 0.45 5.45 0 6 0C6.55 0 7 0.45 7 1V3C7 3.55 6.55 4 6 4Z"
      fill="currentColor"
    />
    <path
      d="M10 4C9.45 4 9 3.55 9 3V1C9 0.45 9.45 0 10 0C10.55 0 11 0.45 11 1V3C11 3.55 10.55 4 10 4Z"
      fill="currentColor"
    />
    <path
      d="M14 4C13.45 4 13 3.55 13 3V1C13 0.45 13.45 0 14 0C14.55 0 15 0.45 15 1V3C15 3.55 14.55 4 14 4Z"
      fill="currentColor"
    />
    <path
      d="M6 20C5.45 20 5 19.55 5 19V17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17V19C7 19.55 6.55 20 6 20Z"
      fill="currentColor"
    />
    <path
      d="M10 20C9.45 20 9 19.55 9 19V17C9 16.45 9.45 16 10 16C10.55 16 11 16.45 11 17V19C11 19.55 10.55 20 10 20Z"
      fill="currentColor"
    />
    <path
      d="M14 20C13.45 20 13 19.55 13 19V17C13 16.45 13.45 16 14 16C14.55 16 15 16.45 15 17V19C15 19.55 14.55 20 14 20Z"
      fill="currentColor"
    />
    <path
      d="M19 7H17C16.45 7 16 6.55 16 6C16 5.45 16.45 5 17 5H19C19.55 5 20 5.45 20 6C20 6.55 19.55 7 19 7Z"
      fill="currentColor"
    />
    <path
      d="M19 11H17C16.45 11 16 10.55 16 10C16 9.45 16.45 9 17 9H19C19.55 9 20 9.45 20 10C20 10.55 19.55 11 19 11Z"
      fill="currentColor"
    />
    <path
      d="M19 15H17C16.45 15 16 14.55 16 14C16 13.45 16.45 13 17 13H19C19.55 13 20 13.45 20 14C20 14.55 19.55 15 19 15Z"
      fill="currentColor"
    />
    <path
      d="M3 7H1C0.45 7 0 6.55 0 6C0 5.45 0.45 5 1 5H3C3.55 5 4 5.45 4 6C4 6.55 3.55 7 3 7Z"
      fill="currentColor"
    />
    <path
      d="M3 11H1C0.45 11 0 10.55 0 10C0 9.45 0.45 9 1 9H3C3.55 9 4 9.45 4 10C4 10.55 3.55 11 3 11Z"
      fill="currentColor"
    />
    <path
      d="M3 15H1C0.45 15 0 14.55 0 14C0 13.45 0.45 13 1 13H3C3.55 13 4 13.45 4 14C4 14.55 3.55 15 3 15Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0697 10.09L9.99969 5L6.92969 10.09L9.99969 11.91L13.0697 10.09Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99969 12.4897L6.92969 10.6797L9.99969 14.9997L13.0697 10.6797L9.99969 12.4897Z"
      fill="currentColor"
    />
  </svg>
</span>
