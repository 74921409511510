<span nz-icon>
  <svg
    style="transform: scaleX(-1)"
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.41 15.51H15.88C15.6148 15.51 15.3604 15.6154 15.1729 15.8029C14.9854 15.9904 14.88 16.2448 14.88 16.51C14.88 16.7752 14.9854 17.0296 15.1729 17.2171C15.3604 17.4046 15.6148 17.51 15.88 17.51H18.28C17.1769 18.6627 15.7544 19.4593 14.1952 19.7974C12.6359 20.1355 11.0112 19.9996 9.52978 19.4072C8.04834 18.8149 6.77787 17.7931 5.88159 16.4732C4.98531 15.1532 4.50418 13.5955 4.5 12C4.5 11.7348 4.39464 11.4804 4.20711 11.2929C4.01957 11.1054 3.76522 11 3.5 11C3.23478 11 2.98043 11.1054 2.79289 11.2929C2.60536 11.4804 2.5 11.7348 2.5 12C2.50529 13.9528 3.08222 15.8613 4.1596 17.49C5.23699 19.1187 6.76767 20.3964 8.56274 21.1652C10.3578 21.9341 12.3387 22.1605 14.261 21.8166C16.1833 21.4727 17.9628 20.5735 19.38 19.23V21C19.38 21.2652 19.4854 21.5196 19.6729 21.7071C19.8604 21.8946 20.1148 22 20.38 22C20.6452 22 20.8996 21.8946 21.0871 21.7071C21.2746 21.5196 21.38 21.2652 21.38 21V16.5C21.3775 16.2416 21.2752 15.9943 21.0943 15.8097C20.9135 15.6251 20.6683 15.5177 20.41 15.51ZM12.5 2C9.93639 2.00731 7.47349 2.99891 5.62 4.77V3C5.62 2.73478 5.51464 2.48043 5.32711 2.29289C5.13957 2.10536 4.88522 2 4.62 2C4.35478 2 4.10043 2.10536 3.91289 2.29289C3.72536 2.48043 3.62 2.73478 3.62 3V7.5C3.62 7.76522 3.72536 8.01957 3.91289 8.20711C4.10043 8.39464 4.35478 8.5 4.62 8.5H9.12C9.38522 8.5 9.63957 8.39464 9.82711 8.20711C10.0146 8.01957 10.12 7.76522 10.12 7.5C10.12 7.23478 10.0146 6.98043 9.82711 6.79289C9.63957 6.60536 9.38522 6.5 9.12 6.5H6.72C7.82247 5.34787 9.24409 4.5515 10.8024 4.21311C12.3607 3.87472 13.9846 4.00975 15.4656 4.60086C16.9466 5.19198 18.2172 6.21221 19.1142 7.5306C20.0113 8.849 20.4938 10.4054 20.5 12C20.5 12.2652 20.6054 12.5196 20.7929 12.7071C20.9804 12.8946 21.2348 13 21.5 13C21.7652 13 22.0196 12.8946 22.2071 12.7071C22.3946 12.5196 22.5 12.2652 22.5 12C22.5 10.6868 22.2413 9.38642 21.7388 8.17317C21.2362 6.95991 20.4997 5.85752 19.5711 4.92893C18.6425 4.00035 17.5401 3.26375 16.3268 2.7612C15.1136 2.25866 13.8132 2 12.5 2Z"
      fill="currentColor"
    />
  </svg>
</span>
