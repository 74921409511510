<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C9.65319 2.00682 7.38355 2.8388 5.58833 4.35031C3.7931 5.86182 2.58667 7.95656 2.18019 10.2679C1.77371 12.5792 2.19308 14.9599 3.3649 16.9932C4.53671 19.0265 6.38633 20.5829 8.58999 21.39H8.64999C10.8141 22.1763 13.1859 22.1763 15.35 21.39H15.41C17.6136 20.5829 19.4633 19.0265 20.6351 16.9932C21.8069 14.9599 22.2263 12.5792 21.8198 10.2679C21.4133 7.95656 20.2069 5.86182 18.4116 4.35031C16.6164 2.8388 14.3468 2.00682 12 2ZM14 19.74C12.6892 20.0868 11.3108 20.0868 9.99999 19.74V16H14V19.74ZM16 18.92V11C16.2652 11 16.5196 10.8946 16.7071 10.7071C16.8946 10.5196 17 10.2652 17 10C17 9.73478 16.8946 9.48043 16.7071 9.29289C16.5196 9.10536 16.2652 9 16 9H14C13.7348 9 13.4804 9.10536 13.2929 9.29289C13.1053 9.48043 13 9.73478 13 10C13 10.2652 13.1053 10.5196 13.2929 10.7071C13.4804 10.8946 13.7348 11 14 11V14H9.99999V11C10.2652 11 10.5196 10.8946 10.7071 10.7071C10.8946 10.5196 11 10.2652 11 10C11 9.73478 10.8946 9.48043 10.7071 9.29289C10.5196 9.10536 10.2652 9 9.99999 9H7.99999C7.73477 9 7.48042 9.10536 7.29288 9.29289C7.10534 9.48043 6.99999 9.73478 6.99999 10C6.99999 10.2652 7.10534 10.5196 7.29288 10.7071C7.48042 10.8946 7.73477 11 7.99999 11V18.92C6.47485 18.0395 5.28288 16.6803 4.60895 15.0533C3.93502 13.4262 3.81678 11.6223 4.27258 9.92124C4.72838 8.22018 5.73274 6.71704 7.12989 5.64497C8.52705 4.5729 10.2389 3.9918 12 3.9918C13.7611 3.9918 15.4729 4.5729 16.8701 5.64497C18.2672 6.71704 19.2716 8.22018 19.7274 9.92124C20.1832 11.6223 20.065 13.4262 19.391 15.0533C18.7171 16.6803 17.5251 18.0395 16 18.92Z"
      fill="currentColor"
    />
  </svg>
</span>
