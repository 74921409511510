<span nz-icon>
  <svg width="113" height="15" viewBox="0 0 113 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.19828 5.99675C6.09234 5.92149 5.9235 5.80074 5.74638 5.66592C5.18263 5.23344 4.53042 4.93044 3.83616 4.77849L1.63958 4.30376H9.05119V0.251157C5.58747 0.346269 3.26922 -0.10448 1.52702 1.08731C0.460175 1.8143 0.0372453 2.9267 0.0372453 4.21691C0.0372453 7.27704 4.42379 8.95019 4.42379 9.89883C4.42379 10.778 3.4571 10.5406 0 10.5795V14.7148C3.76084 14.6519 5.08591 14.886 6.62121 14.4146C8.6804 13.7893 9.63054 12.3701 9.63054 10.706C9.63054 9.86409 7.89662 7.22576 6.19828 5.99675Z"
      fill="#333333"
    />
    <path
      d="M25.4348 2.20764C24.7437 1.50981 23.9209 0.955948 23.0141 0.578167C22.1072 0.200387 21.1344 0.00619775 20.1519 0.00683751C16.0666 0.00683751 12.6691 3.3556 12.6691 7.48594C12.6691 11.5915 16.041 14.9642 20.1519 14.9642C24.2802 14.9642 27.6355 11.5733 27.6355 7.48594C27.6355 6.45211 26.7946 3.56981 25.4348 2.20764ZM22.1987 9.53126C21.9302 9.80018 21.6111 10.0134 21.2599 10.1588C20.9086 10.3041 20.5321 10.3787 20.1519 10.3782C17.76 10.3782 16.545 7.73158 17.6963 5.93686C17.7528 5.84807 17.787 5.74692 17.796 5.64206C17.805 5.5372 17.7884 5.43174 17.7478 5.33465C17.7071 5.23757 17.6436 5.15174 17.5625 5.08451C17.4815 5.01727 17.3854 4.97062 17.2825 4.94852L15.6437 4.59371C19.8854 4.59371 20.0385 4.58875 20.2951 4.59371C21.037 4.63083 21.7363 4.95132 22.2484 5.48897C22.7606 6.02662 23.0465 6.74034 23.047 7.48264C23.047 7.88459 22.7176 9.01269 22.1987 9.53126Z"
      fill="#333333"
    />
    <path
      d="M43.956 2.20764C43.2653 1.50957 42.4427 0.955554 41.5359 0.577754C40.6292 0.199954 39.6564 0.00589731 38.674 0.00683935C32.1595 0.00683935 28.599 7.97389 33.3919 12.7634C38.0847 17.4636 46.1568 14.1313 46.1568 7.48592C46.1576 6.4521 45.3184 3.56981 43.956 2.20764ZM40.7199 9.53124C40.4514 9.79995 40.1325 10.0131 39.7814 10.1584C39.4303 10.3037 39.054 10.3784 38.674 10.3781C35.8839 10.3781 34.7468 6.83172 36.9102 5.19332L34.1417 4.5937C37.2686 4.5937 38.7319 4.5937 38.7931 4.5937C39.5391 4.62504 40.2442 4.94301 40.7613 5.48127C41.2783 6.01953 41.5674 6.73651 41.5683 7.48262C41.5691 7.88457 41.2397 9.01267 40.7207 9.53124H40.7199Z"
      fill="#333333"
    />
    <path
      d="M57.5082 0.25614V6.24653H57.466C55.0211 1.48349 55.1842 1.615 54.7587 1.0923C54.3984 0.671413 53.935 0.351113 53.4138 0.162682C52.4115 -0.215285 50.9623 0.0510281 50.3341 1.34042C49.8864 2.25018 50.1611 2.79935 50.0858 14.7165H54.5924V11.4959C54.5925 10.9056 54.3829 10.3344 54.001 9.8841C53.619 9.4338 53.0895 9.13362 52.5067 9.03703L50.8646 8.76494H54.6089H54.6338C57.0613 13.4965 56.9222 13.4163 57.3418 13.9192C58.892 15.7925 62.0148 15.197 62.0148 12.4007V0.25614H57.5082Z"
      fill="#333333"
    />
    <path
      d="M68.464 0.253906C68.6436 8.87766 68.0179 10.7203 69.1675 12.0709C70.1673 13.2478 71.9973 12.8004 73.281 12.898V14.7175H71.11C68.3084 14.7175 66.5207 12.8153 66.5207 10.0488V0.25638L68.464 0.253906Z"
      fill="#333333"
    />
    <path
      d="M76.1138 14.7154C76.2148 5.89889 75.8655 4.95604 76.4854 3.22913C76.7093 2.58162 77.0649 1.98745 77.5299 1.48403C80.1784 -1.32798 85.9952 -0.234607 85.9952 5.54325V14.7154H84.0518V8.14601H78.8426V6.32648H84.0518C84.0013 5.7831 84.3001 3.98258 83.2242 2.76267C81.7501 1.08787 78.0563 1.41869 78.0563 5.41919V14.7154H76.1138Z"
      fill="#333333"
    />
    <path
      d="M90.088 14.7153C90.1915 4.38205 89.8397 4.191 90.429 2.79575C90.8869 1.70778 91.7508 0.840713 92.8375 0.378251C94.7411 -0.45708 97.9904 0.0548687 99.0291 2.35162C99.6342 3.68897 99.5729 5.91459 97.9226 6.96826C99.3983 7.81517 100.031 8.88126 100.031 10.6247C100.031 13.1158 98.1841 14.7153 95.6904 14.7153H92.7754V12.8958C94.5135 12.8131 96.4378 13.1778 97.416 12.2763C98.3058 11.4559 98.3654 9.56358 97.3333 8.74314C96.2184 7.85819 94.578 8.23284 92.7754 8.14434V6.32481H94.8015C96.5545 6.32481 97.4268 5.33234 97.4268 4.13475C97.4268 2.63777 96.2764 1.81898 94.8429 1.81898C92.9732 1.81898 92.0288 2.96942 92.0288 4.63099V14.712L90.088 14.7153Z"
      fill="#333333"
    />
    <path
      d="M102.926 12.8977C107.164 12.796 108.86 13.1731 109.748 12.4329C110.4 11.8903 110.588 10.5455 109.769 9.75735C107.899 7.96429 105.235 8.02053 103.567 5.70478C102.578 4.33765 102.783 1.34123 105.417 0.488539C106.617 0.0998229 107.275 0.310722 111.609 0.250347V2.06987H106.726C105.684 2.06987 104.969 2.624 104.969 3.78436C104.968 4.00159 105.016 4.21618 105.109 4.41258C105.202 4.60898 105.338 4.78227 105.506 4.9199C105.916 5.27243 106.367 5.57428 106.85 5.81891C109.02 6.95942 112.245 7.89896 112.245 10.8491C112.245 13.0995 110.872 14.7122 108.235 14.7122H102.922L102.926 12.8977Z"
      fill="#333333"
    />
  </svg>
</span>
