<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9825 3.54899C17.6864 3.54899 18.257 2.97838 18.257 2.27449C18.257 1.57061 17.6864 1 16.9825 1C16.2786 1 15.708 1.57061 15.708 2.27449C15.708 2.97838 16.2786 3.54899 16.9825 3.54899Z"
      fill="#9F9D93"
    />
    <path
      d="M17.3383 6.71177C17.934 6.71177 18.4169 6.22889 18.4169 5.63323C18.4169 5.03757 17.934 4.55469 17.3383 4.55469C16.7426 4.55469 16.2598 5.03757 16.2598 5.63323C16.2598 6.22889 16.7426 6.71177 17.3383 6.71177Z"
      fill="#9F9D93"
    />
    <path
      d="M17.0045 9.37408C17.5081 9.37408 17.9163 8.96588 17.9163 8.46234C17.9163 7.9588 17.5081 7.5506 17.0045 7.5506C16.501 7.5506 16.0928 7.9588 16.0928 8.46234C16.0928 8.96588 16.501 9.37408 17.0045 9.37408Z"
      fill="#9F9D93"
    />
    <path
      d="M16.1705 11.5312C16.6007 11.5312 16.9495 11.1824 16.9495 10.7522C16.9495 10.322 16.6007 9.97327 16.1705 9.97327C15.7403 9.97327 15.3916 10.322 15.3916 10.7522C15.3916 11.1824 15.7403 11.5312 16.1705 11.5312Z"
      fill="#9F9D93"
    />
    <path
      d="M15.0474 13.1522C15.4177 13.1522 15.7178 12.8521 15.7178 12.4818C15.7178 12.1115 15.4177 11.8113 15.0474 11.8113C14.6771 11.8113 14.377 12.1115 14.377 12.4818C14.377 12.8521 14.6771 13.1522 15.0474 13.1522Z"
      fill="#9F9D93"
    />
    <path
      d="M13.7917 14.2842C14.1092 14.2842 14.3666 14.0268 14.3666 13.7093C14.3666 13.3918 14.1092 13.1344 13.7917 13.1344C13.4742 13.1344 13.2168 13.3918 13.2168 13.7093C13.2168 14.0268 13.4742 14.2842 13.7917 14.2842Z"
      fill="#9F9D93"
    />
    <path
      d="M20.185 7.11175C20.7833 7.11175 21.2684 6.62669 21.2684 6.02835C21.2684 5.43 20.7833 4.94495 20.185 4.94495C19.5866 4.94495 19.1016 5.43 19.1016 6.02835C19.1016 6.62669 19.5866 7.11175 20.185 7.11175Z"
      fill="#9F9D93"
    />
    <path
      d="M19.8287 9.79511C20.3349 9.79511 20.7453 9.38474 20.7453 8.87851C20.7453 8.37229 20.3349 7.96191 19.8287 7.96191C19.3225 7.96191 18.9121 8.37229 18.9121 8.87851C18.9121 9.38474 19.3225 9.79511 19.8287 9.79511Z"
      fill="#9F9D93"
    />
    <path
      d="M19.0032 11.9312C19.4317 11.9312 19.779 11.5839 19.779 11.1555C19.779 10.7271 19.4317 10.3798 19.0032 10.3798C18.5748 10.3798 18.2275 10.7271 18.2275 11.1555C18.2275 11.5839 18.5748 11.9312 19.0032 11.9312Z"
      fill="#9F9D93"
    />
    <path
      d="M17.8694 13.5506C18.2352 13.5506 18.5317 13.2541 18.5317 12.8883C18.5317 12.5225 18.2352 12.226 17.8694 12.226C17.5036 12.226 17.207 12.5225 17.207 12.8883C17.207 13.2541 17.5036 13.5506 17.8694 13.5506Z"
      fill="#9F9D93"
    />
    <path
      d="M16.6046 14.6713C16.9185 14.6713 17.173 14.4168 17.173 14.1028C17.173 13.7889 16.9185 13.5344 16.6046 13.5344C16.2906 13.5344 16.0361 13.7889 16.0361 14.1028C16.0361 14.4168 16.2906 14.6713 16.6046 14.6713Z"
      fill="#9F9D93"
    />
    <path
      d="M15.3289 15.3644C15.599 15.3644 15.818 15.1454 15.818 14.8753C15.818 14.6052 15.599 14.3862 15.3289 14.3862C15.0588 14.3862 14.8398 14.6052 14.8398 14.8753C14.8398 15.1454 15.0588 15.3644 15.3289 15.3644Z"
      fill="#9F9D93"
    />
    <path
      d="M22.0784 10.634C22.5882 10.634 23.0014 10.2207 23.0014 9.71092C23.0014 9.20112 22.5882 8.78784 22.0784 8.78784C21.5685 8.78784 21.1553 9.20112 21.1553 9.71092C21.1553 10.2207 21.5685 10.634 22.0784 10.634Z"
      fill="#9F9D93"
    />
    <path
      d="M21.2415 12.7797C21.6753 12.7797 22.0269 12.4281 22.0269 11.9943C22.0269 11.5605 21.6753 11.2089 21.2415 11.2089C20.8077 11.2089 20.4561 11.5605 20.4561 11.9943C20.4561 12.4281 20.8077 12.7797 21.2415 12.7797Z"
      fill="#9F9D93"
    />
    <path
      d="M20.1287 14.4057C20.5035 14.4057 20.8073 14.1019 20.8073 13.7271C20.8073 13.3524 20.5035 13.0486 20.1287 13.0486C19.754 13.0486 19.4502 13.3524 19.4502 13.7271C19.4502 14.1019 19.754 14.4057 20.1287 14.4057Z"
      fill="#9F9D93"
    />
    <path
      d="M18.8639 15.5458C19.1894 15.5458 19.4534 15.2819 19.4534 14.9563C19.4534 14.6307 19.1894 14.3668 18.8639 14.3668C18.5383 14.3668 18.2744 14.6307 18.2744 14.9563C18.2744 15.2819 18.5383 15.5458 18.8639 15.5458Z"
      fill="#9F9D93"
    />
    <path
      d="M17.5867 16.234C17.8648 16.234 18.0903 16.0085 18.0903 15.7303C18.0903 15.4522 17.8648 15.2267 17.5867 15.2267C17.3085 15.2267 17.083 15.4522 17.083 15.7303C17.083 16.0085 17.3085 16.234 17.5867 16.234Z"
      fill="#9F9D93"
    />
    <path
      d="M16.3816 16.5611C16.6178 16.5611 16.8092 16.3697 16.8092 16.1336C16.8092 15.8975 16.6178 15.7061 16.3816 15.7061C16.1455 15.7061 15.9541 15.8975 15.9541 16.1336C15.9541 16.3697 16.1455 16.5611 16.3816 16.5611Z"
      fill="#9F9D93"
    />
    <path
      d="M18.4376 21.3627C19.1415 21.3627 19.7121 20.7921 19.7121 20.0882C19.7121 19.3843 19.1415 18.8137 18.4376 18.8137C17.7337 18.8137 17.1631 19.3843 17.1631 20.0882C17.1631 20.7921 17.7337 21.3627 18.4376 21.3627Z"
      fill="#9F9D93"
    />
    <path
      d="M15.35 19.7967C15.9457 19.7967 16.4286 19.3139 16.4286 18.7182C16.4286 18.1225 15.9457 17.6396 15.35 17.6396C14.7544 17.6396 14.2715 18.1225 14.2715 18.7182C14.2715 19.3139 14.7544 19.7967 15.35 19.7967Z"
      fill="#9F9D93"
    />
    <path
      d="M13.9685 17.1646C14.0491 16.6676 13.7116 16.1992 13.2146 16.1186C12.7176 16.0379 12.2492 16.3754 12.1685 16.8724C12.0879 17.3695 12.4254 17.8378 12.9224 17.9185C13.4195 17.9992 13.8878 17.6616 13.9685 17.1646Z"
      fill="#9F9D93"
    />
    <path
      d="M11.4967 15.9296C11.9269 15.9296 12.2757 15.5809 12.2757 15.1507C12.2757 14.7205 11.9269 14.3717 11.4967 14.3717C11.0665 14.3717 10.7178 14.7205 10.7178 15.1507C10.7178 15.5809 11.0665 15.9296 11.4967 15.9296Z"
      fill="#9F9D93"
    />
    <path
      d="M10.5591 13.9846C10.9294 13.9846 11.2296 13.6845 11.2296 13.3142C11.2296 12.9439 10.9294 12.6437 10.5591 12.6437C10.1888 12.6437 9.88867 12.9439 9.88867 13.3142C9.88867 13.6845 10.1888 13.9846 10.5591 13.9846Z"
      fill="#9F9D93"
    />
    <path
      d="M10.1228 12.1887C10.4403 12.1887 10.6976 11.9313 10.6976 11.6138C10.6976 11.2963 10.4403 11.0389 10.1228 11.0389C9.80524 11.0389 9.54785 11.2963 9.54785 11.6138C9.54785 11.9313 9.80524 12.1887 10.1228 12.1887Z"
      fill="#9F9D93"
    />
    <path
      d="M13.5863 22.0705C14.1847 22.0705 14.6697 21.5854 14.6697 20.9871C14.6697 20.3887 14.1847 19.9037 13.5863 19.9037C12.988 19.9037 12.5029 20.3887 12.5029 20.9871C12.5029 21.5854 12.988 22.0705 13.5863 22.0705Z"
      fill="#9F9D93"
    />
    <path
      d="M11.2945 20.1724C11.8008 20.1724 12.2111 19.7621 12.2111 19.2558C12.2111 18.7496 11.8008 18.3392 11.2945 18.3392C10.7883 18.3392 10.3779 18.7496 10.3779 19.2558C10.3779 19.7621 10.7883 20.1724 11.2945 20.1724Z"
      fill="#9F9D93"
    />
    <path
      d="M9.73372 18.1806C10.1621 18.1806 10.5094 17.8333 10.5094 17.4049C10.5094 16.9764 10.1621 16.6292 9.73372 16.6292C9.3053 16.6292 8.95801 16.9764 8.95801 17.4049C8.95801 17.8333 9.3053 18.1806 9.73372 18.1806Z"
      fill="#9F9D93"
    />
    <path
      d="M8.79711 16.2195C9.16292 16.2195 9.45946 15.9229 9.45946 15.5571C9.45946 15.1913 9.16292 14.8948 8.79711 14.8948C8.43131 14.8948 8.13477 15.1913 8.13477 15.5571C8.13477 15.9229 8.43131 16.2195 8.79711 16.2195Z"
      fill="#9F9D93"
    />
    <path
      d="M8.37668 14.4251C8.69151 14.4251 8.94672 14.1699 8.94672 13.8551C8.94672 13.5402 8.69151 13.285 8.37668 13.285C8.06186 13.285 7.80664 13.5402 7.80664 13.8551C7.80664 14.1699 8.06186 14.4251 8.37668 14.4251Z"
      fill="#9F9D93"
    />
    <path
      d="M8.34551 12.8526C8.61562 12.8526 8.83458 12.6337 8.83458 12.3636C8.83458 12.0935 8.61562 11.8745 8.34551 11.8745C8.07541 11.8745 7.85645 12.0935 7.85645 12.3636C7.85645 12.6337 8.07541 12.8526 8.34551 12.8526Z"
      fill="#9F9D93"
    />
    <path
      d="M9.45042 21.7125C9.96022 21.7125 10.3735 21.2993 10.3735 20.7895C10.3735 20.2797 9.96022 19.8664 9.45042 19.8664C8.94062 19.8664 8.52734 20.2797 8.52734 20.7895C8.52734 21.2993 8.94062 21.7125 9.45042 21.7125Z"
      fill="#9F9D93"
    />
    <path
      d="M7.88894 19.7093C8.32272 19.7093 8.67437 19.3576 8.67437 18.9239C8.67437 18.4901 8.32272 18.1384 7.88894 18.1384C7.45516 18.1384 7.10352 18.4901 7.10352 18.9239C7.10352 19.3576 7.45516 19.7093 7.88894 19.7093Z"
      fill="#9F9D93"
    />
    <path
      d="M6.94319 17.7741C7.31794 17.7741 7.62173 17.4703 7.62173 17.0955C7.62173 16.7208 7.31794 16.417 6.94319 16.417C6.56844 16.417 6.26465 16.7208 6.26465 17.0955C6.26465 17.4703 6.56844 17.7741 6.94319 17.7741Z"
      fill="#9F9D93"
    />
    <path
      d="M6.50973 15.9733C6.83439 15.9733 7.09758 15.7101 7.09758 15.3855C7.09758 15.0608 6.83439 14.7976 6.50973 14.7976C6.18507 14.7976 5.92188 15.0608 5.92188 15.3855C5.92188 15.7101 6.18507 15.9733 6.50973 15.9733Z"
      fill="#9F9D93"
    />
    <path
      d="M6.47825 14.3975C6.75641 14.3975 6.9819 14.1721 6.9819 13.8939C6.9819 13.6157 6.75641 13.3903 6.47825 13.3903C6.2001 13.3903 5.97461 13.6157 5.97461 13.8939C5.97461 14.1721 6.2001 14.3975 6.47825 14.3975Z"
      fill="#9F9D93"
    />
    <path
      d="M6.72929 13.0745C6.96541 13.0745 7.15682 12.8831 7.15682 12.647C7.15682 12.4108 6.96541 12.2194 6.72929 12.2194C6.49317 12.2194 6.30176 12.4108 6.30176 12.647C6.30176 12.8831 6.49317 13.0745 6.72929 13.0745Z"
      fill="#9F9D93"
    />
    <path
      d="M2.27449 13.719C2.97838 13.719 3.54899 13.1484 3.54899 12.4445C3.54899 11.7407 2.97838 11.17 2.27449 11.17C1.57061 11.17 1 11.7407 1 12.4445C1 13.1484 1.57061 13.719 2.27449 13.719Z"
      fill="#9F9D93"
    />
    <path
      d="M5.00628 11.536C5.60194 11.536 6.08482 11.0531 6.08482 10.4574C6.08482 9.86179 5.60194 9.37891 5.00628 9.37891C4.41061 9.37891 3.92773 9.86179 3.92773 10.4574C3.92773 11.0531 4.41061 11.536 5.00628 11.536Z"
      fill="#9F9D93"
    />
    <path
      d="M7.62332 10.2486C8.12776 10.2486 8.53668 9.83967 8.53668 9.33524C8.53668 8.8308 8.12776 8.42188 7.62332 8.42188C7.11889 8.42188 6.70996 8.8308 6.70996 9.33524C6.70996 9.83967 7.11889 10.2486 7.62332 10.2486Z"
      fill="#9F9D93"
    />
    <path
      d="M10.0237 9.69309C10.4548 9.69309 10.8043 9.34362 10.8043 8.91252C10.8043 8.48143 10.4548 8.13196 10.0237 8.13196C9.59264 8.13196 9.24316 8.48143 9.24316 8.91252C9.24316 9.34362 9.59264 9.69309 10.0237 9.69309Z"
      fill="#9F9D93"
    />
    <path
      d="M12.0839 9.69153C12.4532 9.69153 12.7527 9.39209 12.7527 9.02271C12.7527 8.65333 12.4532 8.35388 12.0839 8.35388C11.7145 8.35388 11.415 8.65333 11.415 9.02271C11.415 9.39209 11.7145 9.69153 12.0839 9.69153Z"
      fill="#9F9D93"
    />
    <path
      d="M13.7741 10.072C14.0916 10.072 14.349 9.81465 14.349 9.49714C14.349 9.17963 14.0916 8.92224 13.7741 8.92224C13.4566 8.92224 13.1992 9.17963 13.1992 9.49714C13.1992 9.81465 13.4566 10.072 13.7741 10.072Z"
      fill="#9F9D93"
    />
    <path
      d="M3.61353 6.75867C3.8187 6.69662 4.03767 6.69682 4.24272 6.75926C4.44778 6.82169 4.6297 6.94355 4.76548 7.10941C4.90126 7.27528 4.98478 7.47769 5.00549 7.69104C5.02619 7.90439 4.98314 8.11908 4.88179 8.30795C4.78044 8.49683 4.62533 8.65139 4.43611 8.75209C4.24688 8.85279 4.03204 8.89509 3.81877 8.87365C3.60549 8.8522 3.40337 8.76798 3.23798 8.63163C3.07259 8.49528 2.95136 8.31293 2.88964 8.10766C2.80699 7.83278 2.83679 7.53633 2.97252 7.28341C3.10824 7.03049 3.33878 6.84176 3.61353 6.75867Z"
      fill="#9F9D93"
    />
    <path
      d="M6.57285 7.5976C7.07907 7.5976 7.48945 7.18723 7.48945 6.681C7.48945 6.17478 7.07907 5.7644 6.57285 5.7644C6.06663 5.7644 5.65625 6.17478 5.65625 6.681C5.65625 7.18723 6.06663 7.5976 6.57285 7.5976Z"
      fill="#9F9D93"
    />
    <path
      d="M8.95833 7.03402C9.38674 7.03402 9.73403 6.68673 9.73403 6.25831C9.73403 5.8299 9.38674 5.4826 8.95833 5.4826C8.52991 5.4826 8.18262 5.8299 8.18262 6.25831C8.18262 6.68673 8.52991 7.03402 8.95833 7.03402Z"
      fill="#9F9D93"
    />
    <path
      d="M11.0256 7.03563C11.3914 7.03563 11.688 6.73909 11.688 6.37329C11.688 6.00748 11.3914 5.71094 11.0256 5.71094C10.6598 5.71094 10.3633 6.00748 10.3633 6.37329C10.3633 6.73909 10.6598 7.03563 11.0256 7.03563Z"
      fill="#9F9D93"
    />
    <path
      d="M13.2336 7.07994C13.3538 6.78991 13.2161 6.4574 12.926 6.33726C12.636 6.21712 12.3035 6.35486 12.1833 6.64489C12.0632 6.93492 12.2009 7.26743 12.491 7.38757C12.781 7.5077 13.1135 7.36997 13.2336 7.07994Z"
      fill="#9F9D93"
    />
    <path
      d="M14.0174 8.07207C14.2875 8.07207 14.5065 7.85311 14.5065 7.583C14.5065 7.3129 14.2875 7.09393 14.0174 7.09393C13.7473 7.09393 13.5283 7.3129 13.5283 7.583C13.5283 7.85311 13.7473 8.07207 14.0174 8.07207Z"
      fill="#9F9D93"
    />
    <path
      d="M6.17112 5.23806C6.68093 5.23806 7.0942 4.82478 7.0942 4.31498C7.0942 3.80518 6.68093 3.39191 6.17112 3.39191C5.66132 3.39191 5.24805 3.80518 5.24805 4.31498C5.24805 4.82478 5.66132 5.23806 6.17112 5.23806Z"
      fill="#9F9D93"
    />
    <path
      d="M8.51774 3.11823C8.67233 3.10711 8.82674 3.14208 8.96146 3.2187C9.09618 3.29532 9.20516 3.41016 9.27463 3.54871C9.34409 3.68725 9.37093 3.84328 9.35174 3.99708C9.33254 4.15087 9.26819 4.29552 9.1668 4.41274C9.06542 4.52997 8.93156 4.61451 8.78214 4.65567C8.63272 4.69683 8.47445 4.69278 8.32734 4.64401C8.18023 4.59524 8.05087 4.50396 7.95563 4.38169C7.86038 4.25943 7.80352 4.11167 7.79224 3.9571C7.78452 3.85432 7.79719 3.75103 7.82951 3.65317C7.86184 3.55531 7.91319 3.4648 7.98061 3.38685C8.04803 3.30889 8.13019 3.24503 8.22237 3.19893C8.31455 3.15284 8.41493 3.12541 8.51774 3.11823Z"
      fill="#9F9D93"
    />
    <path
      d="M10.6239 4.67612C10.9986 4.67612 11.3024 4.37232 11.3024 3.99757C11.3024 3.62282 10.9986 3.31903 10.6239 3.31903C10.2491 3.31903 9.94531 3.62282 9.94531 3.99757C9.94531 4.37232 10.2491 4.67612 10.6239 4.67612Z"
      fill="#9F9D93"
    />
    <path
      d="M12.3219 5.06799C12.6475 5.06799 12.9114 4.80407 12.9114 4.47851C12.9114 4.15295 12.6475 3.88904 12.3219 3.88904C11.9963 3.88904 11.7324 4.15295 11.7324 4.47851C11.7324 4.80407 11.9963 5.06799 12.3219 5.06799Z"
      fill="#9F9D93"
    />
    <path
      d="M13.628 5.70119C13.9053 5.70119 14.13 5.47643 14.13 5.19917C14.13 4.92191 13.9053 4.69714 13.628 4.69714C13.3507 4.69714 13.126 4.92191 13.126 5.19917C13.126 5.47643 13.3507 5.70119 13.628 5.70119Z"
      fill="#9F9D93"
    />
    <path
      d="M14.5479 5.61699C14.6323 5.61047 14.7168 5.62916 14.7906 5.67069C14.8643 5.71222 14.9241 5.77472 14.9624 5.85027C15.0006 5.92582 15.0155 6.01102 15.0052 6.09506C14.995 6.1791 14.96 6.2582 14.9047 6.32234C14.8495 6.38647 14.7764 6.43276 14.6948 6.45532C14.6132 6.47788 14.5267 6.47571 14.4464 6.44908C14.366 6.42244 14.2953 6.37255 14.2434 6.30572C14.1914 6.23888 14.1604 6.15812 14.1544 6.07367C14.1464 5.96101 14.1832 5.84974 14.257 5.76417C14.3307 5.6786 14.4353 5.62569 14.5479 5.61699Z"
      fill="#9F9D93"
    />
  </svg>
</span>
