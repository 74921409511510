<span nz-icon>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.46 11.48a3 3 0 0 0-3-1.21 3 3 0 0 0-.84.3c-.24-.14-.48-.27-.73-.39A10.46 10.46 0 0 0 14 9.25c-.15 0-.47-.08-.86-.12l.68-4.8 1.2.4a2.5 2.5 0 1 0 .63-1.9l-2.33-.78a1 1 0 0 0-1.31.81l-.89 6.2c-.36 0-.73 0-1.08.1-.509.08-1.01.2-1.5.36a9.37 9.37 0 0 0-1.44.57c-.25.12-.5.26-.75.4a3 3 0 0 0-3.84 1 3 3 0 0 0 .64 4c0 3.58 4 6.49 8.88 6.49 4.88 0 8.88-2.91 8.87-6.41v-.14a.69.69 0 0 0 .13-.11c.203-.19.382-.405.53-.64.14-.243.251-.501.33-.77.07-.278.103-.564.1-.85a3 3 0 0 0-.53-1.58ZM17.5 4a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1Zm2.5 9.41c-.028.087-.065.17-.11.25a1.74 1.74 0 0 1-.17.21c-.07.06-.148.11-.23.15l-.6.3.06.79v.4C18.91 17.94 15.76 20 12 20c-3.76 0-6.88-2.06-6.88-4.57v-.32l.06-.81-.61-.3a1 1 0 0 1-.4-1.43.94.94 0 0 1 .69-.42H5a.939.939 0 0 1 .67.27l.58.54.67-.45A8.873 8.873 0 0 1 8 11.89c.38-.181.77-.338 1.17-.47.4-.121.808-.218 1.22-.29.404-.067.811-.11 1.22-.13.69.016 1.38.083 2.06.2A8.9 8.9 0 0 1 16 12c.369.185.726.392 1.07.62l.67.46.58-.56a1 1 0 0 1 .49-.25.93.93 0 0 1 .55 0 1 1 0 0 1 .44.34 1.1 1.1 0 0 1 .17.51c.021.095.031.193.03.29Z"
      fill="currentColor"
    />
    <path
      d="M15 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM9 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM14.64 16.63a3.86 3.86 0 0 1-2.64.89 3.76 3.76 0 0 1-2.64-.89.502.502 0 0 0-.72.7A4.78 4.78 0 0 0 12 18.52a4.78 4.78 0 0 0 3.36-1.19.502.502 0 1 0-.72-.7Z"
      fill="currentColor"
    />
  </svg>
</span>
