<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13H4C3.46957 13 2.96086 13.2107 2.58579 13.5858C2.21071 13.9609 2 14.4696 2 15V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H9C9.53043 22 10.0391 21.7893 10.4142 21.4142C10.7893 21.0391 11 20.5304 11 20V15C11 14.4696 10.7893 13.9609 10.4142 13.5858C10.0391 13.2107 9.53043 13 9 13ZM4 20V15H9V20H4Z"
      fill="currentColor"
    />
    <path
      d="M20 13H15C14.4696 13 13.9609 13.2107 13.5858 13.5858C13.2107 13.9609 13 14.4696 13 15V20C13 20.5304 13.2107 21.0391 13.5858 21.4142C13.9609 21.7893 14.4696 22 15 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V15C22 14.4696 21.7893 13.9609 21.4142 13.5858C21.0391 13.2107 20.5304 13 20 13ZM15 20V15H20V20H15Z"
      fill="currentColor"
    />
    <path
      d="M9 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V9C2 9.53043 2.21071 10.0391 2.58579 10.4142C2.96086 10.7893 3.46957 11 4 11H9C9.53043 11 10.0391 10.7893 10.4142 10.4142C10.7893 10.0391 11 9.53043 11 9V4C11 3.46957 10.7893 2.96086 10.4142 2.58579C10.0391 2.21071 9.53043 2 9 2ZM4 9V4H9V9H4Z"
      fill="currentColor"
    />
    <path
      d="M17.5 11C18.39 11 19.26 10.7361 20.0001 10.2416C20.7401 9.74715 21.3169 9.04434 21.6575 8.22208C21.9981 7.39981 22.0872 6.49501 21.9135 5.6221C21.7399 4.74918 21.3113 3.94736 20.682 3.31802C20.0526 2.68868 19.2508 2.2601 18.3779 2.08647C17.505 1.91283 16.6002 2.00195 15.7779 2.34254C14.9557 2.68314 14.2529 3.25991 13.7584 3.99994C13.2639 4.73996 13 5.60999 13 6.5C13.0026 7.69266 13.4776 8.83572 14.3209 9.67906C15.1643 10.5224 16.3073 10.9974 17.5 11ZM17.5 4C17.9945 4 18.4778 4.14662 18.8889 4.42133C19.3 4.69603 19.6205 5.08648 19.8097 5.54329C19.9989 6.00011 20.0484 6.50278 19.952 6.98773C19.8555 7.47268 19.6174 7.91814 19.2678 8.26777C18.9181 8.6174 18.4727 8.8555 17.9877 8.95197C17.5028 9.04843 17.0001 8.99892 16.5433 8.8097C16.0865 8.62048 15.696 8.30005 15.4213 7.88893C15.1466 7.4778 15 6.99446 15 6.5C15 5.83696 15.2634 5.20108 15.7322 4.73223C16.2011 4.26339 16.837 4 17.5 4Z"
      fill="currentColor"
    />
  </svg>
</span>
