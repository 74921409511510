<button
  nz-button
  nzType="default"
  class="wen-header-button"
  (click)="auth.openWallet()"
  nzShape="round"
  i18n
>
  Connect
</button>
