<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.075 5.52961L10.4084 1.77961C10.2817 1.70647 10.138 1.66797 9.99171 1.66797C9.84543 1.66797 9.70172 1.70647 9.57504 1.77961L2.90837 5.52961C2.78275 5.60409 2.67891 5.71026 2.60724 5.8375C2.53558 5.96475 2.49861 6.10859 2.50004 6.25461V13.7546C2.50029 13.9023 2.53978 14.0472 2.61446 14.1746C2.68913 14.302 2.79632 14.4073 2.92504 14.4796L9.59171 18.2296C9.71839 18.3028 9.86209 18.3413 10.0084 18.3413C10.1547 18.3413 10.2984 18.3028 10.425 18.2296L17.0917 14.4796C17.2173 14.4051 17.3212 14.299 17.3928 14.1717C17.4645 14.0445 17.5015 13.9006 17.5 13.7546V6.25461C17.4998 6.10695 17.4603 5.96201 17.3856 5.83462C17.3109 5.70723 17.2038 5.60197 17.075 5.52961ZM15.8334 13.2713L10 16.5463L4.16671 13.2713V6.73795L10 3.46295L15.8334 6.73795V13.2713Z"
      fill="currentColor"
    />
    <path
      d="M8.3335 7.50521C8.3335 7.72622 8.42129 7.93818 8.57757 8.09446C8.73385 8.25074 8.94582 8.33854 9.16683 8.33854V12.5052C9.16683 12.7262 9.25463 12.9382 9.41091 13.0945C9.56719 13.2507 9.77915 13.3385 10.0002 13.3385C10.2212 13.3385 10.4331 13.2507 10.5894 13.0945C10.7457 12.9382 10.8335 12.7262 10.8335 12.5052V7.50521C10.8335 7.28419 10.7457 7.07223 10.5894 6.91595C10.4331 6.75967 10.2212 6.67187 10.0002 6.67188H9.16683C8.94582 6.67187 8.73385 6.75967 8.57757 6.91595C8.42129 7.07223 8.3335 7.28419 8.3335 7.50521Z"
      fill="currentColor"
    />
  </svg>
</span>
