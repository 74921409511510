<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM14.29 12.29L11 15.59L9.71 14.29C9.5217 14.1017 9.2663 13.9959 9 13.9959C8.7337 13.9959 8.4783 14.1017 8.29 14.29C8.1017 14.4783 7.99591 14.7337 7.99591 15C7.99591 15.2663 8.1017 15.5217 8.29 15.71L10.29 17.71C10.383 17.8037 10.4936 17.8781 10.6154 17.9289C10.7373 17.9797 10.868 18.0058 11 18.0058C11.132 18.0058 11.2627 17.9797 11.3846 17.9289C11.5064 17.8781 11.617 17.8037 11.71 17.71L15.71 13.71C15.8983 13.5217 16.0041 13.2663 16.0041 13C16.0041 12.7337 15.8983 12.4783 15.71 12.29C15.5217 12.1017 15.2663 11.9959 15 11.9959C14.7337 11.9959 14.4783 12.1017 14.29 12.29Z"
      fill="currentColor"
    />
  </svg>
</span>
