<div
  class="w-full mt-8 text-xs font-medium text-foregrounds-secondary dark:text-foregrounds-secondary-dark"
  i18n
>
  {{ title ? title : 'What network do you want to use for minting?' }}
</div>

<div class="flex flex-wrap items-center w-full">
  <div
    class="py-2 network"
    [ngClass]="{
      network__selected: selectedNetwork === networkTypes.IOTA,
      network__disabled: !isNetworkEnabled(networkTypes.IOTA)
    }"
    (click)="selectNetwork.next(networkTypes.IOTA)"
  >
    <div class="text-lg font-semibold" i18n>IOTA</div>
  </div>
  <div
    class="py-2 network"
    [ngClass]="{
      network__selected: selectedNetwork === networkTypes.SMR,
      network__disabled: !isNetworkEnabled(networkTypes.SMR)
    }"
    (click)="selectNetwork.next(networkTypes.SMR)"
  >
    <div class="text-lg font-semibold" i18n>Shimmer</div>
  </div>
  <div
    *ngIf="!environment.production"
    class="py-2 network"
    [ngClass]="{
      network__selected: selectedNetwork === networkTypes.RMS,
      network__disabled: !isNetworkEnabled(networkTypes.RMS)
    }"
    (click)="selectNetwork.next(networkTypes.RMS)"
  >
    <div class="text-lg font-semibold" i18n>Shimmer</div>
    <div class="w-px h-5 bg-[#66DDCA] ml-1.5 mr-1"></div>
    <div
      class="font-medium -translate-y-1 text-xxs text-foregrounds-secondary dark:text-foregrounds-secondary-dark"
      i18n
    >
      Testnet
    </div>
  </div>
</div>
