<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5299 7.15023C21.3513 7.03868 21.1421 6.98636 20.932 7.00072C20.7219 7.01509 20.5217 7.09541 20.3599 7.23023L15.5199 11.2302L12.9999 2.71023C12.9394 2.50252 12.813 2.32006 12.6399 2.19023C12.4669 2.06041 12.2563 1.99023 12.0399 1.99023C11.8236 1.99023 11.613 2.06041 11.4399 2.19023C11.2668 2.32006 11.1405 2.50252 11.0799 2.71023L8.47995 11.2602L3.63995 7.26023C3.48108 7.11753 3.28071 7.02942 3.06816 7.00882C2.8556 6.98822 2.64204 7.0362 2.45872 7.14574C2.27541 7.25528 2.13198 7.42063 2.04943 7.61757C1.96687 7.81452 1.94953 8.03272 1.99995 8.24023L3.99995 16.1202V21.0002C3.99995 21.2655 4.1053 21.5198 4.29284 21.7073C4.48038 21.8949 4.73473 22.0002 4.99995 22.0002H18.9999C19.2652 22.0002 19.5195 21.8949 19.7071 21.7073C19.8946 21.5198 19.9999 21.2655 19.9999 21.0002V16.1202L21.9999 8.24023C22.0483 8.03217 22.0287 7.8141 21.9442 7.61796C21.8596 7.42181 21.7144 7.2579 21.5299 7.15023ZM4.70995 10.7302L8.35995 13.7302C8.48709 13.8365 8.63864 13.9094 8.80097 13.9426C8.9633 13.9758 9.13132 13.9681 9.28995 13.9202C9.44831 13.8727 9.59241 13.7866 9.70934 13.6696C9.82626 13.5527 9.91237 13.4086 9.95995 13.2502L11.9599 6.44023L13.9599 13.2502C14.0075 13.4086 14.0936 13.5527 14.2106 13.6696C14.3275 13.7866 14.4716 13.8727 14.6299 13.9202C14.7886 13.9681 14.9566 13.9758 15.1189 13.9426C15.2813 13.9094 15.4328 13.8365 15.5599 13.7302L19.2099 10.7302L17.9499 15.7302C17.9541 15.8221 17.9709 15.913 17.9999 16.0002H5.99995C6.00958 15.9205 6.00958 15.8399 5.99995 15.7602L4.70995 10.7302ZM17.9999 20.0002H5.99995V18.0002H17.9999V20.0002Z"
      fill="currentColor"
    />
  </svg>
</span>
