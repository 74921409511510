<span nz-icon>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6443_64777)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 14.168C10.4603 14.168 10.8334 14.5411 10.8334 15.0013V18.3346C10.8334 18.7949 10.4603 19.168 10 19.168C9.53978 19.168 9.16669 18.7949 9.16669 18.3346V15.0013C9.16669 14.5411 9.53978 14.168 10 14.168Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9441 12.9433C13.2695 12.6179 13.7972 12.6179 14.1226 12.9433L16.4809 15.3016C16.8064 15.6271 16.8064 16.1547 16.4809 16.4801C16.1555 16.8056 15.6279 16.8056 15.3024 16.4801L12.9441 14.1218C12.6187 13.7964 12.6187 13.2687 12.9441 12.9433Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.05595 12.9433C7.38138 13.2687 7.38138 13.7964 7.05595 14.1218L4.69761 16.4801C4.37218 16.8056 3.84454 16.8056 3.5191 16.4801C3.19367 16.1547 3.19367 15.6271 3.5191 15.3016L5.87744 12.9433C6.20287 12.6179 6.73051 12.6179 7.05595 12.9433Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1667 10.0013C14.1667 9.54106 14.5398 9.16797 15 9.16797H18.3334C18.7936 9.16797 19.1667 9.54106 19.1667 10.0013C19.1667 10.4615 18.7936 10.8346 18.3334 10.8346H15C14.5398 10.8346 14.1667 10.4615 14.1667 10.0013Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.833313 10.0013C0.833313 9.54106 1.20641 9.16797 1.66665 9.16797H4.99998C5.46022 9.16797 5.83331 9.54106 5.83331 10.0013C5.83331 10.4615 5.46022 10.8346 4.99998 10.8346H1.66665C1.20641 10.8346 0.833313 10.4615 0.833313 10.0013Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4809 3.51752C16.8064 3.84295 16.8064 4.37059 16.4809 4.69603L14.1226 7.05436C13.7972 7.3798 13.2695 7.3798 12.9441 7.05436C12.6187 6.72892 12.6187 6.20129 12.9441 5.87585L15.3024 3.51752C15.6279 3.19208 16.1555 3.19208 16.4809 3.51752Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5191 3.51752C3.84454 3.19208 4.37218 3.19208 4.69761 3.51752L7.05595 5.87585C7.38138 6.20129 7.38138 6.72892 7.05595 7.05436C6.73051 7.3798 6.20287 7.3798 5.87744 7.05436L3.5191 4.69603C3.19367 4.37059 3.19367 3.84295 3.5191 3.51752Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0.832031C10.4603 0.832031 10.8334 1.20513 10.8334 1.66536V4.9987C10.8334 5.45894 10.4603 5.83203 10 5.83203C9.53978 5.83203 9.16669 5.45894 9.16669 4.9987V1.66536C9.16669 1.20513 9.53978 0.832031 10 0.832031Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6443_64777">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</span>
