<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1335 3.97384L10.3002 1.72384C10.1071 1.64934 9.89323 1.64934 9.70016 1.72384L3.86683 3.97384C3.71022 4.03427 3.5755 4.1406 3.48034 4.27888C3.38517 4.41717 3.33399 4.58097 3.3335 4.74884V9.99884C3.3335 14.9488 9.3585 18.1072 9.61683 18.2405C9.73529 18.3019 9.86675 18.3339 10.0002 18.3339C10.1336 18.3339 10.265 18.3019 10.3835 18.2405C10.6418 18.1072 16.6668 14.9488 16.6668 9.99884V4.74884C16.6663 4.58097 16.6151 4.41717 16.52 4.27888C16.4248 4.1406 16.2901 4.03427 16.1335 3.97384ZM15.0002 9.99884C15.0002 13.3322 11.2252 15.8322 10.0002 16.5405C8.76683 15.8322 5.00016 13.3322 5.00016 9.99884V5.32384L10.0002 3.39051L15.0002 5.32384V9.99884Z"
      fill="currentColor"
    />
    <path
      d="M13.3333 7.4987C13.7936 7.4987 14.1667 7.1256 14.1667 6.66536C14.1667 6.20513 13.7936 5.83203 13.3333 5.83203C12.8731 5.83203 12.5 6.20513 12.5 6.66536C12.5 7.1256 12.8731 7.4987 13.3333 7.4987Z"
      fill="currentColor"
    />
    <path
      d="M10.8333 7.4987C11.2936 7.4987 11.6667 7.1256 11.6667 6.66536C11.6667 6.20513 11.2936 5.83203 10.8333 5.83203C10.3731 5.83203 10 6.20513 10 6.66536C10 7.1256 10.3731 7.4987 10.8333 7.4987Z"
      fill="currentColor"
    />
  </svg>
</span>
