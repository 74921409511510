<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.1272 20H21.1272H12.1272Z" fill="currentColor" />
    <path
      d="M12.1272 20H21.1272"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6272 3.50023C17.025 3.1024 17.5646 2.87891 18.1272 2.87891C18.4058 2.87891 18.6816 2.93378 18.939 3.04038C19.1964 3.14699 19.4302 3.30324 19.6272 3.50023C19.8242 3.69721 19.9804 3.93106 20.087 4.18843C20.1936 4.4458 20.2485 4.72165 20.2485 5.00023C20.2485 5.2788 20.1936 5.55465 20.087 5.81202C19.9804 6.06939 19.8242 6.30324 19.6272 6.50023L7.1272 19.0002L3.1272 20.0002L4.1272 16.0002L16.6272 3.50023Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</span>
