<span nz-icon>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4787 6.13007L12.4787 1.13007C12.3267 1.0423 12.1543 0.996094 11.9787 0.996094C11.8032 0.996094 11.6308 1.0423 11.4787 1.13007L2.47875 6.13007C2.32428 6.21689 2.19566 6.3432 2.10605 6.49607C2.01644 6.64894 1.96906 6.82287 1.96875 7.00007V17.0001C1.96906 17.1773 2.01644 17.3512 2.10605 17.5041C2.19566 17.6569 2.32428 17.7832 2.47875 17.8701L11.4787 22.8701C11.6282 22.9548 11.797 22.9995 11.9687 23.0001C12.1375 23.0006 12.3033 22.9557 12.4487 22.8701L21.4487 17.8701C21.6107 17.7885 21.7474 17.6645 21.8443 17.5113C21.9412 17.358 21.9945 17.1813 21.9987 17.0001V7.00007C21.9975 6.82188 21.9486 6.64727 21.8571 6.49431C21.7657 6.34135 21.6351 6.2156 21.4787 6.13007ZM19.9987 16.4101L11.9987 20.8601L3.99875 16.4101V7.59007L11.9987 3.14007L19.9987 7.59007V16.4101Z"
      fill="currentColor"
    />
    <path
      d="M8.54992 12.0495L7.06992 9.76953H5.66992V14.2295H7.06992V11.9895L8.54992 14.2295H9.94992V9.76953H8.54992V12.0495Z"
      fill="currentColor"
    />
    <path
      d="M10.8906 14.2295H12.2906V12.5495H13.5506V11.4995H12.2906V10.8795H14.0406V9.76953H10.8906V14.2295Z"
      fill="currentColor"
    />
    <path
      d="M15.7501 14.2295H17.1501V10.8795H18.3301V9.76953H14.5801V10.8795H15.7501V14.2295Z"
      fill="currentColor"
    />
  </svg>
</span>
