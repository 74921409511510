<span nz-icon>
  <svg height="24" width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 9.5C22 5.36 17.51 2 12 2C6.49 2 2 5.36 2 9.5C2 9.67 2.02 9.83 2.03 10H2V15.02C2 17.11 3.08 19.06 4.81 20.11C6.83 21.33 9.38 22 12 22C14.62 22 17.17 21.33 19.18 20.11C20.92 19.06 22 17.11 22 15.02V10H21.97C21.98 9.83 22 9.67 22 9.5ZM12 4C16.41 4 20 6.47 20 9.5C20 12.53 16.41 15 12 15C7.59 15 4 12.53 4 9.5C4 6.47 7.59 4 12 4ZM11 16.96V19.94C9.95 19.86 8.95 19.66 8 19.35V16.37C8.94 16.68 9.94 16.88 11 16.96ZM13 16.96C13.69 16.91 14.36 16.8 15 16.65V19.64C14.35 19.79 13.68 19.89 13 19.94V16.96ZM4 15.01V13.98C4.57 14.55 5.24 15.06 6 15.48V18.46C6 18.46 5.9 18.41 5.85 18.38C4.71 17.69 4 16.39 4 15V15.01ZM20 15.01C20 16.4 19.29 17.7 18.15 18.39C17.79 18.61 17.4 18.79 17 18.97V15.98C18.17 15.47 19.19 14.79 20 13.98V15.02V15.01Z"
      fill="currentColor"
    />
    <path
      d="M12.99 7.05001C15.5 7.32001 17 8.51001 17 9.50001C17 10.22 16.24 10.97 15.02 11.46C14.51 11.67 14.26 12.25 14.46 12.76C14.62 13.15 14.99 13.39 15.39 13.39C15.51 13.39 15.64 13.37 15.76 13.32C17.79 12.51 19 11.08 19 9.50001C19 7.26001 16.62 5.43001 13.21 5.06001C12.66 5.00001 12.17 5.40001 12.11 5.95001C12.05 6.50001 12.45 6.99001 13 7.05001H12.99Z"
      fill="currentColor"
    />
  </svg>
</span>
