<span nz-icon>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" [attr.stroke]="stroke">
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M20.354 15.354A9 9 0 0 1 8.646 3.646 9.003 9.003 0 0 0 12 21a9.003 9.003 0 0 0 8.354-5.646z"
    />
  </svg>
</span>
