<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.76923 0.921875H1.84616C1.33636 0.921875 0.92308 1.33515 0.92308 1.84495V2.76803C0.92308 3.27783 1.33636 3.69111 1.84616 3.69111H2.76923C3.27904 3.69111 3.69231 3.27783 3.69231 2.76803V1.84495C3.69231 1.33515 3.27904 0.921875 2.76923 0.921875Z"
      fill="currentColor"
    />
    <path
      d="M6.46154 0.921875H5.53846C5.02866 0.921875 4.61539 1.33515 4.61539 1.84495V2.76803C4.61539 3.27783 5.02866 3.69111 5.53846 3.69111H6.46154C6.97134 3.69111 7.38462 3.27783 7.38462 2.76803V1.84495C7.38462 1.33515 6.97134 0.921875 6.46154 0.921875Z"
      fill="currentColor"
    />
    <path
      d="M10.1538 0.921875H9.23077C8.72097 0.921875 8.30769 1.33515 8.30769 1.84495V2.76803C8.30769 3.27783 8.72097 3.69111 9.23077 3.69111H10.1538C10.6636 3.69111 11.0769 3.27783 11.0769 2.76803V1.84495C11.0769 1.33515 10.6636 0.921875 10.1538 0.921875Z"
      fill="currentColor"
    />
    <path
      d="M2.76923 4.61719H1.84616C1.33636 4.61719 0.92308 5.03046 0.92308 5.54026V6.46334C0.92308 6.97314 1.33636 7.38642 1.84616 7.38642H2.76923C3.27904 7.38642 3.69231 6.97314 3.69231 6.46334V5.54026C3.69231 5.03046 3.27904 4.61719 2.76923 4.61719Z"
      fill="currentColor"
    />
    <path
      d="M6.46154 4.61719H5.53846C5.02866 4.61719 4.61539 5.03046 4.61539 5.54026V6.46334C4.61539 6.97314 5.02866 7.38642 5.53846 7.38642H6.46154C6.97134 7.38642 7.38462 6.97314 7.38462 6.46334V5.54026C7.38462 5.03046 6.97134 4.61719 6.46154 4.61719Z"
      fill="currentColor"
    />
    <path
      d="M10.1538 4.61719H9.23077C8.72097 4.61719 8.30769 5.03046 8.30769 5.54026V6.46334C8.30769 6.97314 8.72097 7.38642 9.23077 7.38642H10.1538C10.6636 7.38642 11.0769 6.97314 11.0769 6.46334V5.54026C11.0769 5.03046 10.6636 4.61719 10.1538 4.61719Z"
      fill="currentColor"
    />
    <path
      d="M2.76923 8.30859H1.84616C1.33636 8.30859 0.92308 8.72187 0.92308 9.23167V10.1547C0.92308 10.6645 1.33636 11.0778 1.84616 11.0778H2.76923C3.27904 11.0778 3.69231 10.6645 3.69231 10.1547V9.23167C3.69231 8.72187 3.27904 8.30859 2.76923 8.30859Z"
      fill="currentColor"
    />
    <path
      d="M6.46154 8.30859H5.53846C5.02866 8.30859 4.61539 8.72187 4.61539 9.23167V10.1547C4.61539 10.6645 5.02866 11.0778 5.53846 11.0778H6.46154C6.97134 11.0778 7.38462 10.6645 7.38462 10.1547V9.23167C7.38462 8.72187 6.97134 8.30859 6.46154 8.30859Z"
      fill="currentColor"
    />
    <path
      d="M10.1538 8.30859H9.23077C8.72097 8.30859 8.30769 8.72187 8.30769 9.23167V10.1547C8.30769 10.6645 8.72097 11.0778 9.23077 11.0778H10.1538C10.6636 11.0778 11.0769 10.6645 11.0769 10.1547V9.23167C11.0769 8.72187 10.6636 8.30859 10.1538 8.30859Z"
      fill="currentColor"
    />
  </svg>
</span>
