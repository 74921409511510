<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6829 8.52907C16.4619 8.52907 16.2499 8.61687 16.0937 8.77315C15.9374 8.92943 15.8496 9.14139 15.8496 9.3624V9.97907C15.8449 11.234 15.439 12.4545 14.6913 13.4624C13.9404 14.4677 12.8855 15.2042 11.6829 15.5624C10.4774 15.9207 9.18831 15.8798 8.00791 15.4457C6.82949 15.008 5.82402 14.2013 5.14125 13.1457C4.4457 12.0943 4.10947 10.8459 4.18292 9.5874C4.26081 8.33102 4.7465 7.13436 5.56625 6.17907C6.38819 5.21951 7.49945 4.55275 8.73292 4.27907C9.96 4.00375 11.2432 4.13236 12.3912 4.64574C12.4914 4.69013 12.5993 4.71435 12.7088 4.71701C12.8182 4.71967 12.9272 4.70073 13.0293 4.66126C13.1315 4.62178 13.2249 4.56256 13.3042 4.48697C13.3834 4.41139 13.447 4.32092 13.4913 4.22074C13.5356 4.12061 13.5599 4.01272 13.5625 3.90323C13.5652 3.79374 13.5462 3.6848 13.5068 3.58264C13.4673 3.48048 13.4081 3.3871 13.3325 3.30784C13.2569 3.22858 13.1664 3.165 13.0663 3.12074C11.5797 2.47919 9.92686 2.33026 8.34958 2.69574C6.77427 3.05821 5.35557 3.91352 4.29958 5.1374C3.26244 6.36052 2.63828 7.88007 2.51625 9.47907C2.40987 11.0927 2.82787 12.6974 3.70792 14.0541C4.58844 15.4095 5.8833 16.4442 7.39958 17.0041C8.23182 17.3122 9.11214 17.4702 9.99959 17.4707C10.7192 17.4723 11.4351 17.3684 12.1246 17.1624C13.6745 16.701 15.0357 15.7547 16.0079 14.4624C16.9774 13.1678 17.5061 11.5964 17.5163 9.97907V9.3624C17.5163 9.14139 17.4285 8.92943 17.2722 8.77315C17.1159 8.61687 16.9039 8.52907 16.6829 8.52907Z"
      fill="currentColor"
    />
    <path
      d="M8.58295 8.69519C8.42681 8.53998 8.2156 8.45287 7.99545 8.45287C7.77529 8.45287 7.56409 8.53998 7.40795 8.69519C7.32985 8.77266 7.26785 8.86483 7.22554 8.96638C7.18323 9.06793 7.16145 9.17685 7.16145 9.28686C7.16145 9.39687 7.18323 9.50579 7.22554 9.60734C7.26785 9.70889 7.32985 9.80106 7.40795 9.87853L9.39128 11.8619C9.46846 11.9406 9.5605 12.0032 9.66206 12.0462C9.76363 12.0891 9.87269 12.1114 9.98295 12.1119C10.0932 12.1114 10.2023 12.0891 10.3038 12.0462C10.4054 12.0032 10.4974 11.9406 10.5746 11.8619L17.2413 5.19519C17.3194 5.11772 17.3814 5.02556 17.4237 4.92401C17.466 4.82246 17.4878 4.71354 17.4878 4.60353C17.4878 4.49352 17.466 4.38459 17.4237 4.28304C17.3814 4.1815 17.3194 4.08933 17.2413 4.01186C17.0851 3.85665 16.8739 3.76953 16.6538 3.76953C16.4336 3.76953 16.2224 3.85665 16.0663 4.01186L10.0163 10.0952L8.58295 8.69519Z"
      fill="currentColor"
    />
  </svg>
</span>
