<span nz-icon>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.426 17.457c.268.537.572 1.055.91 1.55a.08.08 0 0 0 .07 0 15.001 15.001 0 0 0 4.5-2.32 15.63 15.63 0 0 0-2.64-10.5 14.681 14.681 0 0 0-3.69-1.18.05.05 0 0 0-.05 0 9.329 9.329 0 0 0-.46 1 12.94 12.94 0 0 0-4.12 0 8.46 8.46 0 0 0-.46-1 .07.07 0 0 0-.06 0 14.74 14.74 0 0 0-3.66 1.16 15.8 15.8 0 0 0-2.69 10.48 15.063 15.063 0 0 0 4.52 2.36.08.08 0 0 0 .07 0c.343-.488.65-.999.92-1.53"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M7.477 16.367a10.48 10.48 0 0 0 9.05 0"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M9.006 14.557a1.75 1.75 0 0 1-1.6-1.85 1.74 1.74 0 0 1 1.6-1.85 1.73 1.73 0 0 1 1.61 1.85 1.74 1.74 0 0 1-1.61 1.85ZM15.007 14.557a1.75 1.75 0 0 1-1.62-1.85 1.74 1.74 0 0 1 1.62-1.85 1.73 1.73 0 0 1 1.61 1.85 1.74 1.74 0 0 1-1.61 1.85Z"
      fill="currentColor"
    />
  </svg>
</span>
