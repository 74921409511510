<div
  class="flex items-center justify-center w-full h-full overflow-hidden bg-center bg-cover not-found-page"
>
  <div
    class="flex flex-col justify-center max-w-lg px-16 py-10 text-center bg-backgrounds-tertiary dark:bg-backgrounds-tertiary-dark rounded-2xl"
  >
    <h2 class="mb-3 text-5xl font-bold text-accent-primary dark:text-accent-primary-dark">404:(</h2>
    <p class="mb-6 text-2xl font-bold" i18n>The page you were looking for was not found</p>
    <a [routerLink]="discoverRoute" nz-button nzType="primary" type="button" i18n>
      Discover Soonaverse
    </a>
  </div>
</div>
