<span nz-icon>
  <svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.90499 3.24056C8.87523 3.24056 10.4764 4.5739 10.4764 6.21676C10.4764 6.44196 10.4464 6.66615 10.3871 6.88342C10.3546 7.01811 10.3701 7.15993 10.4309 7.28442C10.4918 7.40891 10.5941 7.50829 10.7204 7.56541C10.8466 7.62253 10.9888 7.63383 11.1225 7.59735C11.2561 7.56087 11.3729 7.47889 11.4526 7.36556C11.5209 7.39254 11.5935 7.40667 11.6669 7.40723C11.8248 7.40723 11.9762 7.34452 12.0878 7.23289C12.1994 7.12126 12.2621 6.96986 12.2621 6.81199V5.62152C12.2621 5.46365 12.1994 5.31225 12.0878 5.20062C11.9762 5.08899 11.8248 5.02628 11.6669 5.02628C11.602 5.02893 11.5379 5.04096 11.4764 5.06199C11.1227 4.1466 10.4922 3.36442 9.67269 2.8245C8.85321 2.28459 7.88567 2.01387 6.90499 2.05009C5.92431 2.01387 4.95678 2.28459 4.1373 2.8245C3.31783 3.36442 2.68727 4.1466 2.33357 5.06199C2.27213 5.04096 2.20797 5.02893 2.14309 5.02628C1.98522 5.02628 1.83382 5.08899 1.72219 5.20062C1.61056 5.31225 1.54785 5.46365 1.54785 5.62152V6.81199C1.54785 6.96986 1.61056 7.12126 1.72219 7.23289C1.83382 7.34452 1.98522 7.40723 2.14309 7.40723C2.20797 7.40458 2.27213 7.39255 2.33357 7.37152C2.65191 8.27884 3.26893 9.05116 4.08357 9.56199C3.54013 10.2978 3.1569 11.1393 2.95857 12.0322C2.92277 12.1853 2.94899 12.3464 3.03152 12.4802C3.11405 12.614 3.24617 12.7098 3.39904 12.7465H3.53595C3.67273 12.7502 3.80659 12.7066 3.91501 12.6231C4.02342 12.5396 4.09976 12.4214 4.13119 12.2882C4.31081 11.4791 4.68007 10.7242 5.20857 10.0858C5.54912 10.1993 5.90002 10.2791 6.25618 10.3239H6.30976C6.46762 10.3334 6.62279 10.2797 6.74111 10.1748C6.85944 10.0699 6.93124 9.92224 6.94071 9.76437C6.95018 9.60651 6.89655 9.45134 6.79162 9.33302C6.68669 9.21469 6.53905 9.14289 6.38118 9.13342C4.64309 8.9489 3.33357 7.65723 3.33357 6.21676C3.33357 4.5739 4.93476 3.24056 6.90499 3.24056Z"
      fill="currentColor"
    />
    <path
      d="M13.3337 11.1502C13.3857 11.0998 13.4224 11.0359 13.4399 10.9657C13.4573 10.8956 13.4547 10.8219 13.4324 10.7531C13.4101 10.6843 13.369 10.6231 13.3136 10.5765C13.2583 10.5299 13.191 10.4998 13.1195 10.4895L11.929 10.3169C11.8666 10.3076 11.8074 10.2833 11.7565 10.2459C11.7057 10.2086 11.6648 10.1594 11.6373 10.1026L11.1135 9.0371C11.0808 8.97194 11.0307 8.91716 10.9687 8.87887C10.9067 8.84059 10.8352 8.82031 10.7623 8.82031C10.6894 8.82031 10.618 8.84059 10.556 8.87887C10.4939 8.91716 10.4438 8.97194 10.4111 9.0371L9.88136 10.1026C9.8539 10.1594 9.81297 10.2086 9.76213 10.2459C9.71129 10.2833 9.6521 10.3076 9.5897 10.3169L8.39922 10.4895C8.32763 10.4998 8.26036 10.5299 8.20504 10.5765C8.14972 10.6231 8.10857 10.6843 8.08625 10.7531C8.06394 10.8219 8.06136 10.8956 8.0788 10.9657C8.09624 11.0359 8.13301 11.0998 8.18493 11.1502L9.03612 11.9835C9.08057 12.0283 9.11395 12.0828 9.13358 12.1427C9.15322 12.2027 9.15857 12.2664 9.14922 12.3288L8.94684 13.5192C8.93342 13.5912 8.94066 13.6654 8.96774 13.7334C8.99481 13.8014 9.04061 13.8603 9.09981 13.9033C9.15901 13.9463 9.22918 13.9716 9.3022 13.9763C9.37521 13.981 9.44807 13.965 9.51232 13.93L10.5659 13.3764C10.6209 13.3472 10.6822 13.3319 10.7445 13.3319C10.8067 13.3319 10.868 13.3472 10.923 13.3764L11.9766 13.93C12.0408 13.965 12.1137 13.981 12.1867 13.9763C12.2597 13.9716 12.3299 13.9463 12.3891 13.9033C12.4483 13.8603 12.4941 13.8014 12.5212 13.7334C12.5483 13.6654 12.5555 13.5912 12.5421 13.5192L12.3397 12.3288C12.3303 12.2664 12.3357 12.2027 12.3553 12.1427C12.375 12.0828 12.4083 12.0283 12.4528 11.9835L13.3337 11.1502Z"
      fill="currentColor"
    />
  </svg>
</span>
